import * as Yup from 'yup';

const CadenzaSystemFormSchema = (status) => {
  let schema = Yup.object().shape({
    replacementInstallationDate: Yup.string().required('Replacement Installation Date is required'),
    serialNumber: Yup.string().required('Serial Number is required'),
    modelNumber: Yup.string().required('Model Number is required'),
    softwareVersion: Yup.string().required('Software Version is required'),
    manufacturer: Yup.string().required('Manufacturer is required'),
    device: Yup.string().required('Device type is required'),
  });

  if (status === 'edit') {
    schema = schema.shape({
      replaceDevice: Yup.string().required('Is it replace device?'),
    });
  }

  return schema;
}

export default CadenzaSystemFormSchema;
