// React
import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';

// Widget Components
import StateDropdown from '../../StateDropDown';
import api from '../../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

const ProgramForm = ({ toggleModal, refreshPrograms }) => {
  const [programData, setProgramData] = useState({
    programName: '',
    description: '',
    utilityName: '',
    state: '',
    programType: 'Residential',
    programID: '',
    aggregator: '',
  });

  const [aggregatorOptions, setAggregatorOptions] = useState([]);

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAggregatorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Get username
  const username = localStorage.getItem('cdnzUser');

  // Get token
  const token = localStorage.getItem('cdnzAccessToken');

  // Fetch options for the "Aggregator" select
  const fetchAggregatorData = () => {
    api
      .post(
        `/programs/aggregator-list`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
            username: username,
          },
        }
      )
      .then(res => {
        setAggregatorOptions(res.data.array);
      })
      .catch(() => {
        Notify.warning(
          'Aggregator list are not loaded. Please try later.',
          notifyOptions
        );
      });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setProgramData({
      ...programData,
      [name]: value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const username = localStorage.getItem('cdnzUser');
    const token = localStorage.getItem('cdnzAccessToken');

    try {
      const { data } = await api.post(
        '/programs',
        {
          ...programData,
          state: programData.state.toUpperCase(),
        },
        {
          headers: {
            username: username,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.status === 'success') {
        Notify.success(
          `The program ${programData.programName} was created successfully.`,
          notifyOptions
        );
        setTimeout(() => {
          refreshPrograms();
        }, 0);
      }
    } catch (err) {
      Notify.failure(
        `There was an error creating the program ${programData.programName}.`,
        notifyOptions
      );
    }
    toggleModal();
  };

  return (
    <>
      <h1 className="text-2xl font-bold text-center mb-6 mt-4">
        Create Program
      </h1>
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
        <div className="mt-4 mb-2">
          <label
            htmlFor="programID"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Program ID:
          </label>
          <input
            type="text"
            id="programID"
            name="programID"
            value={programData.programID}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mt-4 mb-2">
          <label
            htmlFor="programName"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Program Name:
          </label>
          <input
            type="text"
            id="programName"
            name="programName"
            value={programData.programName}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mt-4 mb-2">
          <label
            htmlFor="utilityName"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Utility Name:
          </label>
          <input
            type="text"
            id="utilityName"
            name="utilityName"
            value={programData.utilityName}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mt-4 mb-2">
          <label
            htmlFor="aggregator"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Aggregator:
          </label>
          <select
            id="aggregator"
            name="aggregator"
            value={programData.aggregator}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="" disabled>
              Select..
            </option>
            {aggregatorOptions.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-4 mb-2 col-span-2">
          <label
            htmlFor="description"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            value={programData.description}
            onChange={handleInputChange}
            className="resize-none shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="5"
          />
        </div>

        <div className="mt-4 mb-2">
          <label
            htmlFor="state"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            State:
          </label>
          <StateDropdown
            name="state"
            access={true}
            inputValue={programData.state}
            onChange={handleInputChange}
            styles = "shadow appearance-none border border-gray-700 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mt-4 mb-2">
          <label
            htmlFor="programType"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Program Type:
          </label>
          <select
            id="programType"
            name="programType"
            value={programData.programType}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="Residential ">Residential</option>
            <option value="Commercial and Industrial">
              Commercial and Industrial
            </option>
          </select>
        </div>

        <div className="col-span-2 flex justify-center mb-2">
          <button
            type="submit"
            className="rounded-md px-4 py-3 text-sm flex justify-center items-center"
          >
            Create Program
          </button>
        </div>
      </form>
    </>
  );
};

export default ProgramForm;
