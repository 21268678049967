// React
import React, { useState } from 'react';

// Components
import Loader from '../../Loader';

// Services
import { deleteCadenzaSystem as deleteCadenzaSystemService } from '../../../services/cadenzaSystemService'; 

const DeleteCadenzaSystem = ({
  toggleModal,
  selectedCadenzaSystem,
  assetName,
  fetchCadenzaSystems,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  // Delete cadenza system
  const deleteCadenzaSystem = async () => {
    setIsDeleting(true);
    try {
      const response = await deleteCadenzaSystemService(assetName, selectedCadenzaSystem.system_id);
      if (response.status === 'success') {
        fetchCadenzaSystems();
      }
    } catch (error) {
      console.error('Error deleting cadenza system:', error);
    } finally {
      setIsDeleting(false);
      toggleModal();
    }
  };

  // Handlers
  const handleDeleteConfirm = () => {
    deleteCadenzaSystem();
    toggleModal();
  };

  const handleDeactivateReject = () => {
    toggleModal();
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <h1 className="text-3xl font-bold text-center py-28">
        {`Are you sure you want to delete "${selectedCadenzaSystem.serial_number}"?`}
      </h1>
      <div className="flex flex-col justify-center gap-4 md:flex-row">
        <button
          className="rounded-md py-2 text-sm flex justify-center"
          onClick={handleDeleteConfirm}
        >
          {isDeleting ? <Loader classNames="w-5 h-5" /> : 'Yes'}
        </button>
        <button
          className="rounded-md py-2 text-sm"
          onClick={handleDeactivateReject}
        >
          No
        </button>
      </div>
    </div>
  );
}

export default DeleteCadenzaSystem;