/**
 * Function to transform keys in a single object from snake_case to camelCase.
 * @param {Object} obj - The object to transform.
 * @return {Object} - The object with transformed keys.
 */

export const transformKeys = (obj) => {
  const transformedObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const transformedKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
      transformedObj[transformedKey] = obj[key];
    }
  }
  return transformedObj;
};
