import React, { useState } from 'react';

// Assets
import { FaTrash } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';

// Components
import Modal from '../modals/Modal';
import DeleteBatteryModule from '../modals/bessInfo/DeleteBatteryModule';
import BatteryModuleForm from '../modals/bessInfo/BatteryModuleForm';

// Helpers
import getFormatDashedDate from '../../helpers/formatDate/getFormatDashedDate';

const BatteryModules = (
  {
    batteryModules,
    assetName,
    fetchBatteryModules,
  }
) => {
  const [hoveredItemId, setHoveredItemId] = useState(false);
  const [selectedBatteryModule, setSetselectedBatteryModule] = useState(null);

  const [showEditModal, setEditModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);

  const toggleEditModal = () => {
    setEditModal(!showEditModal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!showDeleteModal);
  };

  return (
    <div className="border rounded-lg overflow-auto text-sm md:text-base relative w-full">
      <table className="table-auto w-full">
        <thead className="table-head">
          <tr>
            <th className="table-head-item"></th>
            <th className="table-head-item">Serial Number</th>
            <th className="table-head-item">Model Number</th>
            <th className="table-head-item">Software Version</th>
            <th className="table-head-item">Replacement Date</th>
            <th className="table-head-item">Manufacturer</th>
          </tr>
        </thead>
        <tbody>
          {batteryModules?.map(item => {
            return (
              <tr
                key={item.module_id}
                className="border-b"
                onMouseEnter={() => setHoveredItemId(item.module_id)}
                onMouseLeave={() => setHoveredItemId(null)}
              >
                <td
                  className="pr-2 py-2 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();

                    setSetselectedBatteryModule(item);
                  }}
                >
                  <FaTrash
                    className={`inline-flex self-center mr-2 ${hoveredItemId !== item.module_id && 'md:invisible'}`}
                    size={20}
                    onClick={toggleDeleteModal}
                  />
                  <FiEdit
                    className={`inline-flex self-center ${hoveredItemId !== item.module_id && 'md:invisible'}`}
                    size={20}
                    onClick={toggleEditModal}
                  />
                </td>
                <td className="pr-2 py-2 ">{item.serial_number}</td>
                <td className="pr-2 py-2 ">{item.model_number}</td>
                <td className="pr-2 py-2">{item.software_version}</td>
                <td className="pr-2 py-2">{getFormatDashedDate(item.replacement_installation_date)}</td>
                <td className="pr-2 py-2">{item.manufacturer}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {!!showDeleteModal && (
        <Modal
          toggleModal={toggleDeleteModal}
          isSmall={true}
          child={
            <DeleteBatteryModule
              selectedBatteryModule={selectedBatteryModule}
              toggleModal={toggleDeleteModal}
              assetName={assetName}
              fetchBatteryModules={fetchBatteryModules}
            />
          }
        />
      )}

      {!!showEditModal && (
        <Modal
          toggleModal={toggleEditModal}
          isSmall={true}
          child={
            <BatteryModuleForm
              status="edit"
              batteryModule={selectedBatteryModule}
              toggleModal={toggleEditModal}
              fetchBatteryModules={fetchBatteryModules}
              assetName={assetName}
            />
          }
        />
      )}
    </div>
  );
};

export default BatteryModules;
