import * as Yup from 'yup';

const BatteryModuleFormSchema = (status) => {
  let schema = Yup.object().shape({
    replacementInstallationDate: Yup.string().required('Replacement Installation Date is required'),
    serialNumber: Yup.string().required('Serial Number is required'),
    modelNumber: Yup.string().required('Model Number is required'),
    softwareVersion: Yup.string().required('Software Version is required'),
    manufacturer: Yup.string().required('Manufacturer is required'),
  });

  if (status === 'edit') {
    schema = schema.shape({
      replaceModule: Yup.string().required('Is it replace module?'),
    });
  }

  return schema;
}

export default BatteryModuleFormSchema;
