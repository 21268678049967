import React, { useState, useEffect } from 'react';

// Services
import { getAccounts as getAccountsService } from '../services/accountService';
import { getUsers as getUsersService } from '../services/userService';

// Components
import UserContainer from '../components/containers/UserContainer';
import Loader from '../components/Loader';

function Users() {
  const [isUsersDataLoading, setIsUserDataLoading] = useState(true);
  const [isAccountsDataLoading, setIsAccountsDataLoading] = useState(true);

  const [users, setUsers] = useState(null);
  const [accounts, setAccounts] = useState(null);

  useEffect(() => {
    fetchUsersData();
    fetchAccountsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch users data
  const fetchUsersData = async () => {
    try {
      const usersList = await getUsersService();
      setUsers(usersList);
    } catch (error) {
      console.error('Error fetching users data:', error);
    } finally {
      setIsUserDataLoading(false);
    }
  }

  // Fetch accounts data
  const fetchAccountsData = async () => {
    try {
      const accountsList = await getAccountsService();
      setAccounts(accountsList);
    } catch (error) {
      console.error('Error fetching accounts data:', error);
    } finally {
      setIsAccountsDataLoading(false);
    }
  }

  const refetchData = () => {
    fetchUsersData();
    fetchAccountsData();
  }

  return (
    (isUsersDataLoading || isAccountsDataLoading) ? (
      <div className="loader-global-container">
        <Loader classNames="w-32 h-32" />
      </div>
    ) : (
      <UserContainer
        users={users}
        accounts={accounts}
        refetchData={refetchData}
      />
    )
  )
}

export default Users;
