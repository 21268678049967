import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export const getProgramList = async () => {
  try {
    const response = await api.get(
      '/programs',
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    )

    return response.data.result;
  } catch (error) {
    Notify.warning(`Program list not loaded. Please try later.`, notifyOptions);
    throw error;
  }
};

export const enrollProgram = async (programKey, assetName, assetNames, programName) => {
  try {
    const response = await api.post(
      '/programs/enroll',
      {
        data: {
          programKey,
          assetName,
        }
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    )

    if (response.data.status === 'success') {
      Notify.success(
        `The assets: ${assetNames} was successfully enrolled to program ${programName}.`,
        notifyOptions,
      );
    }
  } catch (error) {
    Notify.failure(
      `The assets: ${assetNames} was not enrolled to program ${programName}.`,
      notifyOptions,
    );
    throw error;
  }
};

export const unenrollProgram = async (programKey, assetName, reason, assetNames, programName) => {
  try {
    const response = await api.post(
      '/programs/unenroll',
      {
        data: {
          programKey,
          assetName,
          reason,
        }
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    )

    if (response.data.status === 'success') {
      Notify.success(
        `The assets: ${assetNames} was successfully unenrolled from program ${programName}.`,
        notifyOptions,
      );
    }
  } catch (error) {
    Notify.failure(
      `The assets: ${assetNames} was not unenrolled from program ${programName}.`,
      notifyOptions,
    );
    throw error;
  }
};

export const enrollAssetToEnbala = async (assetName) => {
  try {
    const response = await api.post(
      '/programs/enroll-enbala',
      { assetName },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    )

    if (response.data.status === 'success') {
      Notify.success(
        `The asset ${assetName} was successfully enrolled to Enbala.`,
        notifyOptions,
      );
    }

    return response.data;
  } catch (error) {
    Notify.failure(
      `The asset ${assetName} was not enrolled to Enbala.`,
      notifyOptions,
    );
    throw error;
  }
};

export const getAssetList = async (programKey, state) => {
  try {
    const response = await api.post(
      '/programs/asset-list',
      {
        programKey,
        state,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    )

    return response.data.array;
  } catch (error) {
    Notify.warning(`Asset list not loaded. Please try later.`, notifyOptions);
    throw error;
  }
};

export const getEnrollmentList = async (programID) => {
  try {
    const response = await api.post(
      '/programs/enrollment-list',
      { programID },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    )

    return response.data.result.array;
  } catch (error) {
    Notify.warning(`Enrollment list not loaded. Please try later.`, notifyOptions);
    throw error;
  }
};