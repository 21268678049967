import getAssetStatus from "./getAssetStatus";

const filterVppAssetList = (list, filterValue, enrolled) => {
  return list.filter(
    item =>
      (enrolled ? item.isVPPEnrolled : !item.isVPPEnrolled) &&
      (item.name.toLowerCase().includes(filterValue) ||
        item.bessName.toLowerCase().includes(filterValue) ||
        getAssetStatus(item.errorState).toLowerCase().includes(filterValue) ||
        item.siteDescription.toLowerCase().includes(filterValue) ||
        item.accountName.toLowerCase().includes(filterValue) ||
        `${item.assetContactFirstName} ${item.assetContactLastName}`.toLowerCase().includes(filterValue) ||
        item.modelNumber.toLowerCase().includes(filterValue) ||
        item.gatewaySerialNumber.toLowerCase().includes(filterValue))
  );
};

export default filterVppAssetList;
