// React
import { useEffect, useState } from 'react';

// Services
import { getAccounts as getAccountsService } from '../services/accountService';

// Components
import AccountContainer from '../components/containers/AccountsContainer';
import Loader from '../components/Loader';

function Accounts() {
  const [accounts, setAccounts] = useState();
  const [isAccountsDataLoading, setIsAccountsDataLoading] = useState(true);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const accountsList = await getAccountsService();
      setAccounts(accountsList);
    } catch (error) {
      console.error('Error fetching accounts data:', error);
    } finally {
      setIsAccountsDataLoading(false);
    }
  };

  return (
    isAccountsDataLoading ? (
      <div className="loader-global-container">
        <Loader classNames="w-32 h-32" />
      </div>
    ) : (
      <AccountContainer accounts={accounts} getAccounts={fetchAccounts} />
    )
  )
}

export default Accounts;
