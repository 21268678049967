import { format } from 'date-fns';

const msPerDay = 24 * 60 * 60 * 1000;

export const tickFormatter = (timestamp, dateDifference) => {
  try {
    const date = new Date(timestamp);
    // Check if the date is valid
    if (isNaN(date)) {
      return ''; // Return an empty string for invalid dates
    }

    return dateDifference < msPerDay ? format(date, 'HH:mm') : format(date, 'MM-dd');
  } catch (error) {
    console.error('Error in tickFormatter:', error);
    return ''; // Handle any other errors that may occur during date formatting
  }
};


export const gridTickFormatter = (timestamp, dateDifference) => {
  try {
    const date = new Date(timestamp);

    // Check if the date is valid
    if (isNaN(date)) {
      return '';
    }

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return dateDifference < msPerDay ? `${hours}:${minutes}` : `${month}-${day}`;
  } catch (error) {
    console.error('Error in tickFormatter:', error);
    return '';
  }
};


export const tickFormatterToAssetTime = (timestamp, dateDifference, assetDifferenceFromUtc) => {
  try {
    const date = new Date(timestamp);

    const assetDate = new Date(date.getTime() - (- date.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
  
    // Check if the date is valid
    if (isNaN(assetDate)) {
      return '';
    }

    const hours = String(assetDate.getHours()).padStart(2, '0');
    const minutes = String(assetDate.getMinutes()).padStart(2, '0');
    const month = String(assetDate.getMonth() + 1).padStart(2, '0');
    const day = String(assetDate.getDate()).padStart(2, '0');

    return dateDifference < msPerDay ? `${hours}:${minutes}` : `${month}-${day}`;
  } catch (error) {
    console.error('Error in tickFormatter:', error);
    return '';
  }
};


export const accountPowerTickFormatter = (timestamp, dateDifference) => {
  try {
    const date = new Date(timestamp);
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); // Adjust for local time zone offset

    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');

    return dateDifference < msPerDay ? `${hours}:${minutes}` : `${month}-${day}`;
  } catch (error) {
    console.error('Error in gridTickFormatter:', error);
    return '';
  }
};

export const energyTickFormatter = (timestamp) => {
  try {
    const date = new Date(timestamp);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000); // Adjust for local time zone offset

    // Check if the date is valid
    if (isNaN(localDate)) {
      return '';
    }

    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');

    return `${month}-${day}`;
  } catch (error) {
    console.error('Error in tickFormatter:', error);
    return '';
  }
};