// React
import React, { useState, useEffect } from 'react';

// Components
import Besses from '../../tables/Besses';
import Loader from '../../Loader';

// Utils
import filterVppAssetList from '../../../helpers/widget/filterVppAssetList';

// Services
import { fetchAssetListByVppId as fetchAssetListByVppIdService } from '../../../services/vppService';

const VppEnrollments = ({
  assetName,
}) => {
  const [isVppAssetListLoading, setIsVppAssetListLoading] = useState(true);
  const [assetList, setAssetList] = useState([]);
  const [enrolledBesses, setEnrolledBesses] = useState([]);
  const [availableBesses, setAvailableBesses] = useState([]);
  const [enrolledBessesFilter, setEnrolledBessesFilter] = useState('');
  const [unenrolledBessesFilter, setUnenrolledBessesFilter] = useState('');

  useEffect(() => {
    fetchAssetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Filter asset list on enrolled and available
    setEnrolledBesses(assetList.filter(bess => bess.isVPPEnrolled === true));
    setAvailableBesses(assetList.filter(bess => bess.isVPPEnrolled === false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetList]);

  // Fetch Asset List by VPP id
  const fetchAssetList = async () => {
    try {
      const assetList = await fetchAssetListByVppIdService(assetName);
      setAssetList(assetList);
    } catch (error) {
      console.error('Error fetching asset list:', error);
    } finally {
      setIsVppAssetListLoading(false);
    }
  };

  const handleEnrolledBessesFilterChange = (event) => {
    const newValue = event.target.value.toLowerCase();
    setEnrolledBessesFilter(newValue);

    const filteredEnrolledBessesList = filterVppAssetList(assetList, newValue, true);
    setEnrolledBesses(filteredEnrolledBessesList);
  };

  const handleUnenrolledBessesFilterChange = (event) => {
    const newValue = event.target.value.toLowerCase();
    setUnenrolledBessesFilter(newValue);

    const filteredUnenrolledBessesList = filterVppAssetList(assetList, newValue, false);
    setAvailableBesses(filteredUnenrolledBessesList);
  };

  return (
    <div className="container flex flex-col items-center">
      <h1 className="text-2xl font-bold text-center mb-6">{assetName} Asset Enrollment</h1>

      {isVppAssetListLoading ? (
        <div className='w-full h-full flex justify-center items-center'>
          <Loader classNames='w-32 h-32' />
        </div>
      ) : (
        <>
          <div className="mb-10 w-full">
            <div className='flex items-center justify-between mb-2'>
              <h2 className="text-xl font-semibold">Enrolled BESSes</h2>
              <div className="filter bg-white rounded-lg w-44">
                <input
                  type="text"
                  placeholder="Filter value"
                  className="input-filter rounded-md w-full"
                  value={enrolledBessesFilter}
                  onChange={handleEnrolledBessesFilterChange}
                />
              </div>
            </div>
            <Besses
              filteredBesses={enrolledBesses}
              enrollmentStatus='Enrolled'
              fetchAssetListByVppId={fetchAssetList}
            />
          </div>

          <div className="w-full">
            <div className='flex items-center justify-between mb-2'>
              <h2 className="text-xl font-semibold">Available BESSes</h2>
              <div className="filter bg-white rounded-lg w-44">
                <input
                  type="text"
                  placeholder="Filter value"
                  className="input-filter rounded-md w-full"
                  value={unenrolledBessesFilter}
                  onChange={handleUnenrolledBessesFilterChange}
                />
              </div>
            </div>
            <Besses
              filteredBesses={availableBesses}
              enrollmentStatus='Unenrolled'
              fetchAssetListByVppId={fetchAssetList}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default VppEnrollments;