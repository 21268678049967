export const calculateTimeRangeUtc = () => {
  const startOfDayLocalTime = new Date();
  startOfDayLocalTime.setHours(0, 0, 0, 0);

  const endOfDayLocalTime = new Date();
  endOfDayLocalTime.setHours(23, 59, 59, 999);

  const startToUTC = startOfDayLocalTime.toUTCString();
  const endToUTC = endOfDayLocalTime.toUTCString();

  // Calculate dates for account energy in UTC
  const now = new Date();
  const dayForEnergy = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));

  return { startToUTC, endToUTC, startOfDayLocalTime, endOfDayLocalTime, dayForEnergy };
}

export const calculateDateRangeUtc = (startDateParam, endDateParam) => {
  let startDate = startDateParam instanceof Date ? startDateParam : new Date(startDateParam);
  let endDate = endDateParam instanceof Date ? endDateParam : new Date(endDateParam);

  if (!startDate || !endDate) return;

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    console.error("Invalid Date objects");
    return;
  }

  const startOfDayLocalTime = startDate;
  startOfDayLocalTime.setHours(0, 0, 0, 0);

  const endOfDayLocalTime = endDate;
  endOfDayLocalTime.setHours(23, 59, 59, 999);

  const startToUTC = startOfDayLocalTime.toUTCString();
  const endToUTC = endOfDayLocalTime.toUTCString();

  // Extract day, month, and year in local time
  const localStartDay = startDate.getDate();
  const localStartMonth = startDate.getMonth();
  const localStartYear = startDate.getFullYear();

  const localEndDay = endDate.getDate();
  const localEndMonth = endDate.getMonth();
  const localEndYear = endDate.getFullYear();

  // Create new date objects using the extracted components for Account Energy
  const startOfDayUTC = new Date(Date.UTC(localStartYear, localStartMonth, localStartDay, 0, 0, 0, 0));
  const endOfDayUTC = new Date(Date.UTC(localEndYear, localEndMonth, localEndDay, 23, 59, 59, 999));

  return { startToUTC, endToUTC, startOfDayUTC, endOfDayUTC };
}