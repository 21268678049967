import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export const getUsers = async () => {
  try {
    const response = await api.get(
      '/users',
      {
        headers: {
          authorization: `Bearer ${token}`,
          username,
        },
      }
    );

    return response?.data?.users?.users;
  } catch (error) {
    Notify.warning('Users data not loaded. Please try later.', notifyOptions);
    throw error;
  }
};