import React, { useState } from 'react';
import { unenrollProgram as unenrollProgramService } from '../../../services/programService';

export default function UnenrollAssetFromProgram({
  toggleModal,
  activeAssets,
  activeProgram,
  checkboxState,
  toggleCheckbox,
  setActiveAsset,
}) {
  const assetNames = activeAssets.join(', ');
  const [reason, setReason] = useState('');
  const [isLoadingUnenroll, setIsLoadingUnenroll] = useState(false);

  // Handlers
  const handleUnenrollConfirm = async () => {
    setIsLoadingUnenroll(true);
    try {
      await unenrollProgramService(activeProgram.programKey, activeAssets, reason, assetNames, activeProgram.programName);
    } catch (error) {
      console.error('Error unenrolling from program:', error);
    } finally {
      setIsLoadingUnenroll(false);
    }

    if (checkboxState) {
      // Clear checkboxes and activeAssets
      Object.keys(checkboxState).forEach((bessName) => {
        toggleCheckbox(bessName, false); // Uncheck checkboxes
      });
      setActiveAsset([]); // Clear activeAssets
    }
    toggleModal();
  };

  const handleUnenrollReject = () => {
    if (checkboxState) {
      // Clear checkboxes and activeAssets
      Object.keys(checkboxState).forEach((bessName) => {
        toggleCheckbox(bessName, false); // Uncheck checkboxes
      });
      setActiveAsset([]); // Clear activeAssets
    }
    toggleModal();
  };
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <div className='flex flex-col items-center gap-6'>
      <h1 className='text-3xl font-bold text-center py-10'>
        {`Are you sure you want to unenroll assets: "${assetNames}" from program "${activeProgram.programName}"?`}
      </h1>

      <div className='w-full'>
        <label htmlFor='reason'
          className='block text-gray-700 text-sm font-bold mb-2'>
          Reason:
        </label>
        <textarea
          id='reason'
          name='reason'
          className='resize-none rounded-md border w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          rows='5'
          value={reason}
          onChange={handleReasonChange}
          required
        />
      </div>

      <div className='flex flex-col justify-center gap-4 md:flex-row'>
        <button
          className='rounded-md py-2 text-sm flex justify-center'
          onClick={handleUnenrollConfirm}
        >
          {isLoadingUnenroll ? 'Loading...' : 'Yes'}
        </button>
        <button
          className='rounded-md py-2 text-sm'
          onClick={handleUnenrollReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
