import getDateWithoutTime from "./getDateWithoutTime";

const getFilteredEvents = (events, statusFilter) => {
  if (statusFilter === null) {
    return events;
  } else {
    return events.filter(
      event =>
        getDateWithoutTime(event.startTime) ===
        getDateWithoutTime(statusFilter)
    );
  }
};

export default getFilteredEvents;