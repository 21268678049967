import { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';

// Assets
import { FiFilter } from 'react-icons/fi';
import { ReactComponent as BuildingPlus } from '../../assets/building-house-add.svg';

// Components
import AccountRow from '../tables/AccountRow';
import SearchFilter from '../controls/SearchFilter';
import Modal from '../modals/Modal';
import AccountDetails from '../modals/account/AccountDetails';
import AccountForm from '../modals/account/AccountForm';

const AccountContainer = ({
  accounts: accountsData = [],
  getAccounts,
}) => {
  const [showFilterControls, setShowFilterControls] = useState(false);
  const [showViewModal, setViewModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [accounts, setAccounts] = useState(accountsData);
  const [activeAccount, setActiveAccount] = useState();
  const [search, setSearch] = useState();

  const hideFilterControls = e => {
    if (
      e.target.className !== 'filter-controls' &&
      e.target.parentNode.className !== 'filter-controls' &&
      !e.target.parentNode.closest('.filter-controls') &&
      showFilterControls
    ) {
      setShowFilterControls(false);
    }
  };

  useEffect(() => {
    setAccounts(accounts);
  }, [accounts]);

  useEffect(() => {
    document.addEventListener('mousedown', hideFilterControls);
    document.addEventListener('touchstart', hideFilterControls);
    return () => {
      document.removeEventListener('mousedown', hideFilterControls);
      document.removeEventListener('touchstart', hideFilterControls);
    };

    // NOTE: check deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilterControls]);

  // Manage toggle of modal display
  const toggleViewModal = () => {
    setViewModal(!showViewModal);
  };

  const toggleEditModal = () => {
    setEditModal(!showEditModal);
  };

  const toggleNewModal = () => {
    setShowNewModal(!showNewModal);
  };

  // Get the details of the target account
  const getActiveAccountDetails = id => {
    const activeAccount = accounts.filter(account => {
      return account.accountID === id;
    });

    return activeAccount[0];
  };

  // Independent assignment of target account
  const assignActiveAccount = id => {
    if (!id) {
      setActiveAccount();
      return;
    }
    const data = getActiveAccountDetails(id);
    setActiveAccount(data);
  };

  // Data update from form filter
  const updateData = data => {
    setAccounts(data);
  };

  // Search parameters
  const updateSearchLabel = search => {
    setSearch(search);
  };

  return (
    <main className="flex flex-col items-center p-5 grow mb-6 max-h-85vh">
      <div className="container flex flex-row justify-end mb-4">
        {!!search && (
          <div className="mr-6 mt-1 border rounded-full px-4 py-1 border-slate-500 color-primary">
            Searching for {search}
          </div>
        )}
        
        <div className="mr-4 mt-1 flex items-center relative filter-controls">
          <button
            type="button"
            onClick={() => setShowFilterControls(!showFilterControls)}
          >
            <IconContext.Provider value={{ color: '#0F3052' }}>
              <FiFilter size={24} />
            </IconContext.Provider>
          </button>
          <div
            className={`${showFilterControls ? 'hidden md:block' : 'hidden'} 
            container-border bg-white py-4 px-6 absolute md:top-0 md:right-0 md:mr-10 z-40`}
          >
            <SearchFilter
              initialData={accounts}
              updateSearchLabel={updateSearchLabel}
              updateData={updateData}
              params={[
                'account-ID',
                'account-Name',
                'emergency-Contact-Name',
                'emergency-Contact-Phone-Number',
                'emergency-Contact-Email',
                'emergency-Contact-Role',
              ]}
            />
          </div>
        </div>
        <div className="flex items-center relative">
          <button
            type="button"
            className="mr-4 h-7"
            onClick={() => {
              assignActiveAccount();
              toggleNewModal();
            }}
          >
            <BuildingPlus className="h-full custom-icon" size={24} />
          </button>
        </div>
      </div>
      <section className="container container-border bg-white  overflow-auto">
        <table className="table-auto w-full table-primary">
          <thead className="table-head">
            <tr>
              <th className="table-head-item"></th>
              <th className="table-head-item">Account Name</th>
              <th className="table-head-item">Salesforce Link</th>
              <th className="table-head-item">Account Contact</th>
              <th className="table-head-item">Phone</th>
              <th className="table-head-item">Email</th>
              <th className="table-head-item">Account Contact Role</th>
            </tr>
          </thead>
          <tbody>
            {accounts?.map(account => (
              <AccountRow
                key={account.accountName}
                {...account}
                toggleModal={toggleViewModal}
                toggleEditModal={toggleEditModal}
                assignActiveAccount={assignActiveAccount}
              />
            ))}
          </tbody>
        </table>
      </section>

      {!!showViewModal && (
        <Modal
          toggleModal={toggleViewModal}
          isSmall={true}
          child={<AccountDetails account={activeAccount} />}
        />
      )}

      {!!showNewModal && (
        <Modal
          toggleModal={toggleNewModal}
          isSmall={true}
          child={
            <AccountForm
              status="create"
              account={activeAccount}
              toggleModal={toggleNewModal}
              getAccounts={getAccounts}
            />
          }
        />
      )}

      {!!showEditModal && (
        <Modal
          toggleModal={toggleEditModal}
          isSmall={true}
          child={
            <AccountForm
              status="edit"
              account={activeAccount}
              getAccounts={getAccounts}
              toggleModal={toggleEditModal}
            />
          }
        />
      )}
    </main>
  );
};

export default AccountContainer;
