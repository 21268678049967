// React
import React, { useState } from 'react';

// Assets
import Loader from '../../Loader';
import CustomDatePicker from '../../CustomDatePicker';

// Formik
import {
  Formik,
  Field,
  ErrorMessage,
  Form
} from 'formik';

// Validation schema
import BatteryModuleFormSchema from './BatteryModuleFormSchema';

// Services
import { upsertBatteryModule as upsertBatteryModuleService } from '../../../services/batteryService';

// Helpers
import transformDateForBessVppInfoTables from '../../../helpers/formatDate/transformDateForBessVppInfoTables';

// Utils
import { transformKeys } from '../../../utils/transformKeys';

const BatteryModuleForm = ({
  assetName,
  toggleModal,
  status,
  batteryModule: initialBatteryModule,
  fetchBatteryModules,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const transformedBatteryModules = transformKeys(initialBatteryModule);

  const initialValues = {
    replacementInstallationDate: '',
    serialNumber: '',
    modelNumber: '',
    softwareVersion: '',
    manufacturer: '',
  };

  // Create or update battery module request
  const handleSubmit = async (values) => {
    const transformedDate = transformDateForBessVppInfoTables(values.replacementInstallationDate);

    const batteryModuleData = {
      assetName,
      replacement_installation_date: transformedDate,
      serial_number: values.serialNumber,
      model_number: values.modelNumber,
      software_version: values.softwareVersion,
      manufacturer: values.manufacturer,
    };

    if (status === 'edit') {
      batteryModuleData.module_id = transformedBatteryModules.moduleId;
      batteryModuleData.is_replaced = Number(values.replaceModule);
    }

    try {
      setIsLoading(true);
      const response = await upsertBatteryModuleService(batteryModuleData, status);
      if (response.status === 'success') {
        fetchBatteryModules();
      }
    } catch (error) {
      console.error(`Error ${status === 'create' ? 'creating' : 'updating'} battery module:`, error);
    } finally {
      setIsLoading(false);
      toggleModal();
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold text-center mb-6">
          {status === 'edit' ? 'Edit' : 'Create'} Battery Module
        </h1>
        <Formik
          initialValues={status === 'edit' ? { ...transformedBatteryModules, replaceModule: '' } : initialValues}
          validationSchema={BatteryModuleFormSchema(status)}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <div className="md:grid grid-cols-2 gap-8 mb-10 mt-2">
                <div>
                  <h3 className="mt-4 mb-2 font-bold">Serial Number</h3>
                  <Field
                    type="text"
                    name="serialNumber"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Serial Number"
                  />
                  <ErrorMessage
                    name="serialNumber"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">Model Number</h3>
                  <Field
                    type="text"
                    name="modelNumber"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Model Number"
                  />
                  <ErrorMessage
                    name="modelNumber"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">Software Version</h3>
                  <Field
                    type="text"
                    name="softwareVersion"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Software Version"
                  />
                  <ErrorMessage
                    name="softwareVersion"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">Manufacturer</h3>
                  <Field
                    type="text"
                    name="manufacturer"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Manufacturer"
                  />
                  <ErrorMessage
                    name="manufacturer"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">{status === 'edit' && values.replaceModule === '1' ? 'Replacement' : 'Installation'} Date</h3>
                  <CustomDatePicker name="replacementInstallationDate" />
                  <ErrorMessage
                    name="replacementInstallationDate"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                {status === 'edit' && (
                  <div>
                    <h3 className="mt-4 mb-4 font-bold">Replace Module</h3>
                    <div role="group" aria-labelledby="replaceModuleLabel">
                      <label className="font-bold">
                        <Field type="radio" name="replaceModule" value="1" className="mb-1 mr-1" />
                        Yes
                      </label>

                      <label className="ml-4 font-bold">
                        <Field type="radio" name="replaceModule" value="0" className="mb-1 mr-1" />
                        No
                      </label>
                    </div>
                    <ErrorMessage
                      name="replaceModule"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                )}

              </div>
              <div className="flex justify-around">
                <button
                  className="rounded-md px-4 py-2 text-sm flex justify-center items-center"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isLoading ? (
                    <Loader classNames="w-5 h-5" />
                  ) : status === 'edit' ? (
                    'Save'
                  ) : (
                    'Create Battery Module'
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BatteryModuleForm;
