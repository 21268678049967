import { useState, useEffect, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

function AssetLocations({ assets }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [mapCenter, setMapCenter] = useState(null);
  const [zoom, setZoom] = useState(parseInt(localStorage.getItem('lastZoom')) || 4);
  const mapRef = useRef(null);
  const centerChangeByUser = useRef(false);

  useEffect(() => {
    if (isLoaded && mapCenter === null) {
      // Set initial map center after the Google Maps API is loaded
      setMapCenter({
        lat: parseFloat(localStorage.getItem('lastLat')) || 39.8097343,
        lng: parseFloat(localStorage.getItem('lastLng')) || -98.5556199,
      });
    }
  }, [isLoaded, mapCenter]);

  useEffect(() => {
    const handleMapMove = () => {
      if (mapRef.current && !centerChangeByUser.current) {
        const newCenter = mapRef.current.getCenter();
        const currentCenter = mapCenter;
        if (newCenter && (newCenter.lat() !== currentCenter.lat || newCenter.lng() !== currentCenter.lng)) {
          setMapCenter({
            lat: newCenter.lat(),
            lng: newCenter.lng(),
          });
        }
      }
      centerChangeByUser.current = false;
    };

    if (mapRef.current && window.google && window.google.maps) {
      mapRef.current.addListener('dragend', handleMapMove);
    }

    return () => {
      if (mapRef.current && window.google && window.google.maps) {
        window.google.maps.event.clearListeners(mapRef.current, 'dragend');
      }
    };
  }, [mapCenter]);

  useEffect(() => {
    if (mapCenter) {
      localStorage.setItem('lastLat', mapCenter.lat);
      localStorage.setItem('lastLng', mapCenter.lng);
    }
  }, [mapCenter]);

  useEffect(() => {
    localStorage.setItem('lastZoom', zoom);
  }, [zoom]);

  const handleZoomChange = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="p-6 w-full h-full">
      <GoogleMap
        zoom={zoom}
        center={mapCenter}
        options={{ disableDefaultUI: true }}
        mapContainerClassName="map-container"
        onLoad={(map) => {
          mapRef.current = map;
          // Set initial map center if not already set
          if (!mapCenter) {
            setMapCenter({
              lat: parseFloat(localStorage.getItem('lastLat')) || 39.8097343,
              lng: parseFloat(localStorage.getItem('lastLng')) || -98.5556199,
            });
          }
        }}
        onZoomChanged={handleZoomChange}
      >
        {assets?.map((element, index) => {
          const lat = element.gpsCoordinates.latitude;
          const long = element.gpsCoordinates.longitude;
          return (
            <Marker
              key={element.assetId + index}
              position={{ lat: lat, lng: long }}
              icon={{
                url: require('../../assets/cadenza-map-pin.ico'),
              }}
            />
          );
        })}
      </GoogleMap>
    </div>
  );
}

export default AssetLocations;
