// Helpers
import getHealthClass from "../../helpers/widget/getHealthClass";

const AssetList = props => {
  const results = props.filteredAssets.filter(
    item => item.accountOwningID === ''
  );

  return (
    <div className="border rounded-lg grow pb-2 px-2 mx-3 md:px-4 md:mx-8 overflow-auto text-sm md:text-base relative">
      <table className="table-auto w-full ">
        <thead className="table-head">
          <tr>
            <th className="table-head-item">Asset Name</th>
            <th className="table-head-item text-center">Status</th>
            <th className="table-head-item">Site Description</th>
            <th className="table-head-item">Account Name</th>
            <th className="table-head-item">Asset Contact</th>
            <th className="table-head-item">Model Number</th>
            <th className="table-head-item">Gateway SN</th>
          </tr>
        </thead>
        <tbody>
          {results?.map((item, index) => {
            return (
              <tr
                key={item.assetId + index}
                className="border-b cursor-pointer"
                onClick={() => {
                  props.setTargetDetails(item);
                }}
              >
                <td className="pr-2 py-2 relative">
                  <span
                    type="button"
                    role="button"
                    onClick={() => {
                      props.updateModal(props.modalState, props.modalAction);
                      props.passItem(item);
                    }}
                    onMouseEnter={() => { }}
                  >
                    {item.name}
                  </span>
                </td>
                <td className="pr-2 py-2  ">
                  <div className="flex flex-row justify-center items-center align-middle whitespace-nowrap h-full">
                    <span
                      className={`health ${getHealthClass(item.errorState)} mr-2`}
                    ></span>
                    {item.errorState === 'ok' && 'Healthy'}
                    {item.errorState === 'warning' && 'Unhealthy'}
                    {item.errorState ===
                      'faults' && 'Fault'}
                  </div>
                </td>
                <td className="pr-2 py-2">{item.siteDescription}</td>
                <td className="pr-2 py-2">{item.accountName}</td>
                <td className="pr-2 py-2">{item.assetContactName}</td>
                <td className="pr-2 py-2">{item.modelNumber}</td>
                <td className="pr-2 py-2">{item.gatewaySerialNumber}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AssetList;
