import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export const fetchCadenzaSystems = async (assetName) => {
  try {
    const response = await api.post(
      `/device/cadenza-systems`,
      { assetName },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data.systems;
  } catch (error) {
    Notify.warning('Cadenza systems are not loaded. Please try later.', notifyOptions);
    throw error;
  }
};

export const upsertCadenzaSystem = async (body, action) => {
  try {
    const response = await api.post(
      `/device/cadenza-system/${action === 'create' ? 'create' : 'update'}`,
      body,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    if (response.data.status === 'success') {
      Notify.success(
        `Cadenza system was ${action === 'create' ? 'created' : 'updated'} successfully`,
        notifyOptions,
      );
    }

    return response.data;
  } catch (error) {
    Notify.failure(
      `There was an error ${action === 'create' ? 'creating' : 'updating'} the Cadenza system`,
      notifyOptions,
    );
    throw error;
  }
};

export const deleteCadenzaSystem = async (assetName, moduleId) => {
  try {
    const response = await api.post(
      `/device/cadenza-system/delete`,
      {
        assetName,
        module_id: moduleId,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    if (response.data.status === 'success') {
      Notify.success('Cadenza system was deleted successfully', notifyOptions);
    }

    return response.data;
  } catch (error) {
    Notify.failure('There was an error deleting the Cadenza system', notifyOptions);
    throw error;
  }
};
