// Services
import { updateDashboardLayout } from "../services/layoutService";

const prepLayout = (newLayout, prevLayout, updatedLayout) => {
  if (!newLayout || !prevLayout || !updatedLayout) {
    return;
  }

  newLayout.forEach(element => {
    delete element['isBounded'];
    delete element['isDraggable'];
    delete element['isResizable'];
    delete element['moved'];
    delete element['resizeHandles'];
    delete element['static'];

    prevLayout.forEach(prevElement => {
      if (prevElement['i'] === element['i']) {
        prevElement['w'] = element['w'];
        prevElement['h'] = element['h'];
        prevElement['x'] = element['x'];
        prevElement['y'] = element['y'];
      }
      if (!prevElement['minH']) {
        prevElement['minH'] = 6;
      }
      if (!prevElement['maxH']) {
        prevElement['maxH'] = 100;
      }
      if (!prevElement['minW']) {
        prevElement['minW'] = 2;
      }
      if (!prevElement['maxW']) {
        prevElement['maxW'] = 100;
      }
    });
  });

  updateDashboardLayout(prevLayout, updatedLayout)
    .catch(error => {
      console.error(`Failed to update dashboard layout ${updatedLayout}:`, error);
    });
};

export default prepLayout;
