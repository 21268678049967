import React, { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import { assetRealTimeDataSelectors } from '../../redux/assetRealTimeData/assetRealTimeDataSelectors';

// Access
import accessControl from '../../helpers/accessControl';

// Assets
import { FaTrash } from 'react-icons/fa';
import getFormatDashedDate from '../../helpers/formatDate/getFormatDashedDate';
import formatTime from '../../helpers/formatDate/formatTimeForTables';

const ChargingEvents = (
  {
    toggleDeleteChargingEventModal,
    setActiveChargingEventID,
    isWidget,
    events,
    assetDifferenceFromUtc,
  }
) => {
  const filteredChargingEvents = useSelector(assetRealTimeDataSelectors.getFilteredChargingEvents);

  const [hoveredItemId, setHoveredItemId] = useState(false);

  const convertToAssetTime = (datetime) => {
    const eventDate = new Date(datetime);
    eventDate.setMinutes(eventDate.getMinutes() + assetDifferenceFromUtc + eventDate.getTimezoneOffset());

    const date = getFormatDashedDate(eventDate);
    const time = formatTime(eventDate);
    return { date, time };
  };

  const containerClassName = isWidget
    ? "border rounded-lg grow pb-2 px-2 mx-3 md:px-4 md:mx-8 overflow-auto text-sm md:text-base relative w-auto"
    : "border rounded-lg grow pb-2 px-2 mt-6 md:px-0 overflow-auto text-sm md:text-base relative w-auto";

  const showEvents = events && events.length !== 0 ?
    events :
    filteredChargingEvents;

  return (
    <div
      className={containerClassName}
      style={!isWidget && showEvents.length > 5 ? {
        maxHeight: 'calc(5 * 42px)',
        overflowY: 'auto',
      } : {}}
    >
      <table className="table-auto w-full">
        <thead className="table-head">
          <tr>
            <th className="table-head-item"></th>
            <th className="table-head-item">Event Number</th>
            <th className="table-head-item">Event Type</th>
            <th className="table-head-item">Date Start</th>
            <th className="table-head-item">Date End</th>
            <th className="table-head-item">Time Start</th>
            <th className="table-head-item">Time End</th>
            {isWidget && (
              <>
                <th className="table-head-item">Power Level (kW)</th>
                <th className="table-head-item">Target SOC (%)</th>
                <th className="table-head-item">Event Source</th>
                <th className="table-head-item">Created By</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {showEvents?.map(item => {
            const startDate = convertToAssetTime(item.start_datetime);
            const endDate = convertToAssetTime(item.end_datetime);

            return (
              <tr
                key={item.event_id}
                className="border-b"
                onMouseEnter={() => setHoveredItemId(item.event_id)}
                onMouseLeave={() => setHoveredItemId(null)}
              >
                {accessControl.isAdministrator() ? (
                  <td
                    className="pr-2 py-2 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();

                      setActiveChargingEventID(item.event_id)

                      toggleDeleteChargingEventModal()
                    }}
                  >
                    <FaTrash
                      className={`inline-flex self-center ${hoveredItemId !== item.event_id && 'md:invisible'}`}
                      size={20}
                    />
                  </td>
                ) : (
                  <td>
                    <FaTrash
                      className="inline-flex self-center md:invisible"
                      size={20}
                    />
                  </td>
                )}
                <td className="pr-2 py-2 ">{item.event_number}</td>
                <td className="pr-2 py-2 ">{item.event_type}</td>
                <td className="pr-2 py-2">{startDate.date}</td>
                <td className="pr-2 py-2">{endDate.date}</td>
                <td className="pr-2 py-2">{startDate.time}</td>
                <td className="pr-2 py-2">{endDate.time}</td>
                {isWidget && (
                  <>
                    <td className="pr-2 py-2 ">{item.power_level}</td>
                    <td className="pr-2 py-2 ">{item.target_soc}</td>
                    <td className="pr-2 py-2 ">{item.source}</td>
                    <td className="pr-2 py-2 ">{item.created_by}</td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ChargingEvents;
