import React, { useState, useEffect, useRef } from 'react';

import { US_STATES } from '../constants/USStates';

export default function StateDropdown({
  title,
  access,
  inputValue,
  onChange,
  styles = '',
  ...props
}) {
  const [searchTerm, setSearchTerm] = useState(inputValue || '');
  const [filteredStates, setFilteredStates] = useState(US_STATES);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Update the search term when inputValue changes
  useEffect(() => {
    setSearchTerm(inputValue.toUpperCase() || '');
    if (US_STATES.includes(inputValue)) {
      setIsDropdownOpen(false);
    }
  }, [inputValue]);

  const handleInputChange = e => {
    const value = e.target.value.toUpperCase();
    setSearchTerm(value);

    const matchedStates = US_STATES.filter(state => state.startsWith(value));

    setFilteredStates(matchedStates);
    setIsDropdownOpen((value && !US_STATES.includes(value)) || value === '');
    onChange(e); // Notify parent component
  };

  const handleStateSelection = state => {
    setSearchTerm(state);
    setIsDropdownOpen(false);
    onChange({ target: { name: props.name, value: state } }); // Update parent state
  };

  const handleBlur = e => {
    // Check if the blur event is caused by interaction with the dropdown
    if (dropdownRef.current && dropdownRef.current.contains(e.relatedTarget)) {
      return;
    }

    // Clear the input if the value is invalid
    if (!US_STATES.includes(searchTerm)) {
      setSearchTerm('');
      onChange({ target: { name: props.name, value: '' } }); // Clear parent value
    }
    setIsDropdownOpen(false);
    setFilteredStates(US_STATES);
  };

  return (
    <label className="relative block mb-4" ref={dropdownRef}>
      {title && <span className="block mb-3">{title}</span>}
      <input
        className={styles || 'rounded-md w-full py-1.5 px-2 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400'}
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={() => {
          if (!US_STATES.includes(searchTerm)) {
            setIsDropdownOpen(true);
          }
        }}
        onBlur={handleBlur}
        disabled={!access}
        placeholder="Type or select a state"
        {...props}
      />
      {isDropdownOpen && (
        <ul
          className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-md max-h-40 overflow-y-auto w-full"
          tabIndex="-1" // Makes the dropdown focusable
        >
          {filteredStates.length > 0 ? (
            filteredStates.map(state => (
              <li
                key={state}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                tabIndex="0" // Allow focus on dropdown items
                onClick={() => handleStateSelection(state)}
              >
                {state}
              </li>
            ))
          ) : (
            <li className="px-4 py-2 text-gray-500">No matching states</li>
          )}
        </ul>
      )}
    </label>
  );
}
