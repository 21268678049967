import Switch from "react-switch";

const Switcher = ({
  checked,
  onChange,
  disabled,
}) => {
  return (
    <Switch
      checked={!!checked}
      onChange={onChange}
      disabled={disabled}
      onColor="#a2c7ee"
      offColor='#f1f0ea'
      onHandleColor="#0f3052"
      offHandleColor='#f1f0ea'
      handleDiameter={25}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={25}
      width={60}
      id="toggleDPS"
      uncheckedIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 16,
            color: "#0f3052",
            fontWeight: 800,
          }}
        >
          Off
        </div>
      }
      checkedIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 15,
            color: "#0f3052",
            fontWeight: 800,
          }}
        >
          On
        </div>
      }
    />
  );
};

export default Switcher;
