import React, { useState } from 'react';
import api from '../../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

const ProgramEditForm = ({ toggleModal, activeProgram, refreshPrograms }) => {
  const [programData, setProgramData] = useState({
    programName: activeProgram.programName,
    description: activeProgram.description,
    utilityName: activeProgram.utilityName,
    state: activeProgram.state,
    programType: activeProgram.programType,
    programID: activeProgram.programID,
    aggregator: activeProgram.aggregator,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProgramData({
      ...programData,
      [name]: value,
    });
  };

  const handleDeactivateReject = async (e) => {
    e.preventDefault();
    const username = localStorage.getItem('cdnzUser');
    const token = localStorage.getItem('cdnzAccessToken');
    try {
      const { data } = await api.patch(`/programs/${activeProgram.programKey}`,
        {
          ...programData,
        }, {
        headers: {
          username: username,
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.status === 'success') {
        Notify.success(
          `The program ${programData.programName} was updated successfully.`,
          notifyOptions,
        );
        setTimeout(() => {
          refreshPrograms();
        }, 1000);
      }
    } catch (err) {
      Notify.failure(
        `There was an error updating the program ${programData.programName}.`,
        notifyOptions,
      );
    }
    toggleModal();
  };

  return (
    <>
      <h1 className='text-2xl font-bold text-center mb-6 mt-4'>Update
        Program</h1>
      <form onSubmit={handleDeactivateReject}
        className='grid grid-cols-2 gap-4'>

        <div className='mt-4 mb-2'>
          <label
            htmlFor='programID'
            className='block text-gray-700 text-sm font-bold mb-2'
          >
            Program ID:
          </label>
          <span>{programData.programID}</span>
        </div>

        <div className='mt-4 mb-2'>
          <label
            htmlFor='programName'
            className='block text-gray-700 text-sm font-bold mb-2'
          >
            Program Name:
          </label>
          <span>{programData.programName}</span>
        </div>

        <div className='mt-4 mb-2'>
          <label
            htmlFor='utilityName'
            className='block text-gray-700 text-sm font-bold mb-2'
          >
            Utility Name:
          </label>
          <span>{programData.utilityName}</span>
        </div>

        <div className='mt-4 mb-2'>
          <label
            htmlFor='aggregator'
            className='block text-gray-700 text-sm font-bold mb-2'
          >
            Aggregator:
          </label>
          <span>{programData.aggregator}</span>
        </div>

        <div className='mt-4 mb-2 col-span-2'>
          <label
            htmlFor='description'
            className='block text-gray-700 text-sm font-bold mb-2'
          >
            Description:
          </label>
          <textarea
            id='description'
            name='description'
            value={programData.description}
            onChange={handleInputChange}
            className='resize-none shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            rows='5'
          />
        </div>

        <div className='mt-4 mb-2'>
          <label
            htmlFor='state'
            className='block text-gray-700 text-sm font-bold mb-2'
          >
            State:
          </label>
          <input
            type='text'
            id='state'
            name='state'
            value={programData.state}
            onChange={handleInputChange}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            required
          />
        </div>

        <div className='mt-4 mb-2'>
          <label
            htmlFor='programType'
            className='block text-gray-700 text-sm font-bold mb-2'
          >
            Program Type:
          </label>
          <select
            id='programType'
            name='programType'
            value={programData.programType}
            onChange={handleInputChange}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            required
          >
            <option value='Residential '>Residential</option>
            <option value='Commercial and Industrial'>Commercial and
              Industrial
            </option>
          </select>
        </div>

        <div className='col-span-2 flex justify-center mb-2'>
          <button
            type='submit'
            className='rounded-md px-4 py-3 text-sm flex justify-center items-center'
          >
            Update Program
          </button>
        </div>
      </form>
    </>
  );
};

export default ProgramEditForm;
