// React
import React, { useState } from 'react';

// Components
import Loader from '../../Loader';

// Services
import { deleteBatteryModule as deleteBatteryModuleService } from '../../../services/batteryService';

export default function DeleteBatteryModule({
  toggleModal,
  selectedBatteryModule,
  assetName,
  fetchBatteryModules
}) {
  const [isDeleting, setIsDeleting] = useState(false);

  // Delete battery module
  const deleteBatteryModule = async () => {
    setIsDeleting(true);
    try {
      const response = await deleteBatteryModuleService(assetName, selectedBatteryModule.module_id);
      if (response.status === 'success') {
        fetchBatteryModules();
      }
    } catch (error) {
      console.error('Error deleting battery module:', error);
    } finally {
      setIsDeleting(false);
      toggleModal();
    }
  };

  // Handlers
  const handleDeleteConfirm = () => {
    deleteBatteryModule();
    toggleModal();
  };

  const handleDeactivateReject = () => {
    toggleModal();
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <h1 className="text-3xl font-bold text-center py-28">
        {`Are you sure you want to delete "${selectedBatteryModule.serial_number}"?`}
      </h1>
      <div className="flex flex-col justify-center gap-4 md:flex-row">
        <button
          className="rounded-md py-2 text-sm flex justify-center"
          onClick={handleDeleteConfirm}
        >
          {isDeleting ? <Loader classNames="w-5 h-5" /> : 'Yes'}
        </button>
        <button
          className="rounded-md py-2 text-sm"
          onClick={handleDeactivateReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
