import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export const fetchAssetListByVppId = async (vppID) => {
  try {
    const response = await api.post(
      `/device/vpp-asset-list`,
      { vppID },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );
    return response.data.assetList;
  } catch (error) {
    Notify.warning('Asset list are not loaded. Please try later.', notifyOptions);
    throw error;
  }
};

export const enrollBessToVpp = async (assetId, enrollmentStatus) => {
  try {
    const response = await api.post(
      `/device/vpp-enroll-bess`,
      {
        assetId,
        enrollmentStatus,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    );

    if (response.data.status === 'success') {
      Notify.success(
        `BESS was successfully ${enrollmentStatus ? 'enrolled to' : 'unenrolled from'} VPP`,
        notifyOptions,
      );
      return response.data;
    } else {
      throw new Error('Operation failed');
    }
  } catch (error) {
    Notify.failure(
      `There was an error ${enrollmentStatus ? 'enrolling BESS to VPP' : 'unenrolling BESS from VPP'}`,
      notifyOptions,
    );
    throw error;
  }
};
