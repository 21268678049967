import React, { useEffect, useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { BsCalendarEvent } from 'react-icons/bs';
import { useFormikContext } from 'formik';
import getFormatDashedDate from '../helpers/formatDate/getFormatDashedDate';

const CustomDatePicker = ({ name, handleBlur }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const inputRef = useRef(null);

  useEffect(() => {
    setFieldTouched(name, false);
  }, [name, setFieldTouched]);

  const date = new Date();
  const selectedDate = values[name]
    ? new Date(values[name]).setMinutes(new Date(values[name]).getMinutes() + date.getTimezoneOffset())
    : null;

  const handleChange = (date) => {
    const newDate = getFormatDashedDate(date[0]);
    setFieldValue(name, newDate);
    if (handleBlur) {
      handleBlur();
    }
  };

  return (
    <div className="flex items-center justify-around w-full h-9 relative cursor-pointer">
      <Flatpickr
        name={name}
        placeholder="mm.dd.yyyy"
        className="calendar-input z-40"
        value={selectedDate}
        onChange={handleChange}
        options={{
          dateFormat: 'm.d.Y',
          enableTime: false,
        }}
      />
      <BsCalendarEvent className="custom-icon absolute right-4" />
      {/* Display the selected date in a custom input field */}
      <input
        type="text"
        name={name}
        ref={inputRef}
        className="hidden" // Hide the input field, but it will hold the selected date
      />
    </div>
  );
};

export default CustomDatePicker;
