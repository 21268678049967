import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export const getAccounts = async () => {
  try {
    const response = await api.get(
      '/accounts',
      {
        headers: {
          authorization: `Bearer ${token}`,
          username,
        },
      }
    );

    return response?.data?.account?.accountList;
  } catch (error) {
    Notify.warning('Accounts data not loaded. Please try later.', notifyOptions);
    throw error;
  }
};

export const createAccount = async (payload) => {
  try {
    const response = await api.post(
      '/accounts',
      { ...payload },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username,
        },
      }
    );

    if (response.data.status === 'success') {
      Notify.success(
        `The account "${payload.accountName}" was successfully created.`,
        notifyOptions
      );
    } 

    return response.data;
  } catch (error) {
    Notify.warning('Account not created. Please try later.', notifyOptions);
    throw error;
  }
};

export const updateAccount = async (payload) => {
  try {
    const response = await api.put(
      '/accounts',
      { ...payload },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username,
        },
      }
    );

    if (response.data.status === 'success') {
      Notify.success(
        `The account "${payload.accountName}" was successfully updated.`,
        notifyOptions
      );
    }

    return response.data;
  } catch (error) {
    Notify.warning('Account not updated. Please try later.', notifyOptions);
    throw error;
  }
};