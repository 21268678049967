import React, { useState } from 'react';
import { enrollProgram as enrollProgramService } from '../../../services/programService';

export default function EnrollAssetToProgram({
  toggleModal,
  activeAssets,
  activeProgram,
  checkboxState,
  toggleCheckbox,
  setActiveAsset,
}) {
  const assetNames = activeAssets.join(', ');
  const [isLoadingEnroll, setIsLoadingEnroll] = useState(false);

  // Handlers
  const handleEnrollConfirm = async () => {
    setIsLoadingEnroll(true);
    try {
      await enrollProgramService(activeProgram.programKey, activeAssets, assetNames, activeProgram.programName);
    } catch (error) {
      console.error('Error enrolling to program:', error);
    } finally {
      setIsLoadingEnroll(false);
    }

    if (checkboxState) {
      // Clear checkboxes and activeAssets
      Object.keys(checkboxState).forEach((bessName) => {
        toggleCheckbox(bessName, false); // Uncheck checkboxes
      });
      setActiveAsset([]); // Clear activeAssets
    }
    toggleModal();
  };

  const handleEnrollReject = () => {
    if (checkboxState) {
      // Clear checkboxes and activeAssets
      Object.keys(checkboxState).forEach((bessName) => {
        toggleCheckbox(bessName, false); // Uncheck checkboxes
      });
      setActiveAsset([]); // Clear activeAssets
    }
    toggleModal();
  };

  return (
    <div className='flex flex-col items-center gap-6'>
      <h1 className='text-3xl font-bold text-center py-28'>
        {`Are you sure you want to enroll assets: "${assetNames}" to program "${activeProgram.programName}"?`}
      </h1>
      <div className='flex flex-col justify-center gap-4 md:flex-row'>
        <button
          className='rounded-md py-2 text-sm flex justify-center'
          onClick={handleEnrollConfirm}
        >
          {isLoadingEnroll ? 'Loading...' : 'Yes'}
        </button>
        <button
          className='rounded-md py-2 text-sm'
          onClick={handleEnrollReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
