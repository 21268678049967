import * as Yup from 'yup';

const CreateDischargeEventSchema = () => {
  let schema = Yup.object().shape({
    powerLevel: Yup.number()
      .typeError('Power Level must be a number')
      .moreThan(0, 'Power Level must be more than 0')
      .required('Power Level is required'),
    startDate: Yup.date()
      .typeError('Invalid date format')
      .required('Start date is required'),
    endDate: Yup.date()
      .typeError('Invalid date format')
      .required('End date is required'),
    startHour: Yup.string().required('Start hour is required'),
    startMinute: Yup.string().required('Start minute is required'),
    endHour: Yup.string().required('End hour is required'),
    endMinute: Yup.string().required('End minute is required'),
    days: Yup.array()
      .of(Yup.string())
      .min(1, 'Please choose at least one day of the week'),
  });

  return schema;
};

export default CreateDischargeEventSchema;
