const getFormatDashedDate = date => {
  if (!date) {
    return '';
  }

  const newDate = new Date(date);

  if (isNaN(newDate.getTime())) {
    return '';
  }
  
  const day = String(newDate.getDate()).padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear();
  return `${year}-${month}-${day}`;
};

export default getFormatDashedDate;
