import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export const fetchCadenzaModules = async (assetName) => {
  try {
    const response = await api.post(
      `/device/cadenza-modules`,
      { assetName },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data.modules;
  } catch (error) {
    Notify.warning('Cadenza modules are not loaded. Please try later.', notifyOptions);
    throw error;
  }
};

export const fetchBatteryModules = async (assetName) => {
  try {
    const response = await api.post(
      `/device/battery-modules`,
      { assetName },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data.batteryModules;
  } catch (error) {
    Notify.warning('Battery modules are not loaded. Please try later.', notifyOptions);
    throw error;
  }
};

export const updateCadenzaModules = async (newValues) => {
  try {
    const response = await api.post(
      `/device/cadenza-modules/update`,
      newValues,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    if (response.data.status === 'success') {
      Notify.success('Cadenza modules were updated successfully', notifyOptions);
    }
    
    return response.data;
  } catch (error) {
    Notify.warning('Cadenza modules are not updated. Please try later.', notifyOptions);
    throw error;
  }
};

export const upsertBatteryModule = async (body, action) => {
  try {
    const response = await api.post(
      `/device/battery-module/${action === 'create' ? 'create' : 'update'}`,
      body,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    if (response.data.status === 'success') {
      Notify.success(
        `Battery module was ${action === 'create' ? 'created' : 'updated'} successfully`,
        notifyOptions,
      );
    }

    return response.data;
  } catch (error) {
    Notify.failure(
      `There was an error ${action === 'create' ? 'creating' : 'updating'} the battery module`,
      notifyOptions,
    );
    throw error;
  }
};

export const deleteBatteryModule = async (assetName, moduleId) => {
  try {
    const response = await api.post(
      `/device/battery-module/delete`,
      {
        assetName,
        module_id: moduleId,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    if (response.data.status === 'success') {
      Notify.success('Battery module was deleted successfully', notifyOptions);
    }

    return response.data;
  } catch (error) {
    Notify.failure('There was an error deleting the battery module', notifyOptions);
    throw error;
  }
};
