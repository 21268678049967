import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export const updateDashboardLayout = async (prevLayout, updatedLayout) => {
  try {
    await api.put(
      '/device/dashboard',
      {
        widgets: prevLayout,
        updatedLayout,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );
  } catch (error) {
    Notify.warning(
      `Layout ${updatedLayout} not updated. Please try later.`,
      notifyOptions
    );
    throw error;
  }
};

export const fetchBessLayout = async isPlatformAdministrator => {
  try {
    const response = await api.get(
      `/device/${isPlatformAdministrator ? 'bess-layout-pa' : 'bess-layout'}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data;
  } catch (error) {
    Notify.warning(
      'BESS layout are not loaded. Please try later.',
      notifyOptions
    );
    throw error;
  }
};

export const fetchSecondWattnodeLayout = async EM2Identifier => {
  try {
    const response = await api.get(
      `device/second-wattnode/${
        EM2Identifier ? 'layout' : 'layout-without-em2'
      }`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data;
  } catch (error) {
    Notify.warning(
      'Second wattnode layout are not loaded. Please try later.',
      notifyOptions
    );
    throw error;
  }
};

export const fetchVppLayout = async isPlatformAdministrator => {
  try {
    const response = await api.get(
      `/device/${isPlatformAdministrator ? 'vpp-layout-pa' : 'vpp-layout'}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data;
  } catch (error) {
    Notify.warning(
      'VPP layout are not loaded. Please try later.',
      notifyOptions
    );
    throw error;
  }
};
