import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export const getHomeDashboardData = async () => {
  try {
    const response = await api.get(
      '/device/dashboard',
      {
        headers: {
          authorization: `Bearer ${token}`,
          username,
        },
      }
    );

    return response?.data;
  } catch (error) {
    Notify.warning('Home dashboard data not loaded. Please try later.', notifyOptions);
    throw error;
  }
};