const getArrOfStartReadyEvent = events => {
  const dates = [];

  events.forEach(event => {
    dates.push(event.startTime);
  });

  return dates;
};

export default getArrOfStartReadyEvent;
