function getConnectionStatus({
  connection,
  wattnode1SerialNumber,
  EM2SerialNumber,
}) {
  const statuses = [];

  // Check the connection status
  if (connection !== "Online") {
    statuses.push("Asset");
  }

  // Check wattnode1SerialNumber status
  if (!wattnode1SerialNumber) {
    statuses.push("EM1");
  }

  // Check EM2SerialNumber status
  if (!EM2SerialNumber) {
    statuses.push("EM2");
  }

  // If all are connected
  if (
    connection === "Online" &&
    wattnode1SerialNumber &&
    EM2SerialNumber
  ) {
    return "All connected";
  }

  // Return concatenated statuses
  return statuses.join(", ") + " not connected";
}

export default getConnectionStatus;