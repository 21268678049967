const getHealthClass = state => {
  if (state === 'ok') {
    return 'health-healthy';
  } else if (state === 'warning') {
    return 'health-unhealthy';
  }

  return 'health-down';
};

export default getHealthClass;
