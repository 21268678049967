// React
import React, { useState } from 'react';

// Services
import { upsertCadenzaSystem as upsertCadenzaSystemService } from '../../../services/cadenzaSystemService';

// Assets
import Loader from '../../Loader';
import CustomDatePicker from '../../CustomDatePicker';

// Formik
import {
  Formik,
  Field,
  ErrorMessage,
  Form
} from 'formik';

// Validation schema
import CadenzaSystemFormSchema from './CadenzaSystemFormSchema';

// Helpers
import transformDateForBessVppInfoTables from '../../../helpers/formatDate/transformDateForBessVppInfoTables';

// Utils
import { transformKeys } from '../../../utils/transformKeys';

const CadenzaSystemForm = ({
  assetName,
  toggleModal,
  status,
  cadenzaSystem: initialCadenzaSystem,
  fetchCadenzaSystems,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const transformedCadenzaSystem = transformKeys(initialCadenzaSystem);

  const initialValues = {
    replacementInstallationDate: '',
    serialNumber: '',
    modelNumber: '',
    softwareVersion: '',
    manufacturer: '',
    device: '',
  };

  // Create or update cadenza system request
  const handleSubmit = async (values) => {
    const transformedDate = transformDateForBessVppInfoTables(values.replacementInstallationDate);

    const cadenzaSystemData = {
      assetName,
      replacement_installation_date: transformedDate,
      serial_number: values.serialNumber,
      model_number: values.modelNumber,
      software_version: values.softwareVersion,
      manufacturer: values.manufacturer,
      device: values.device,
    };

    if (status === 'edit') {
      cadenzaSystemData.system_id = transformedCadenzaSystem.systemId;
      cadenzaSystemData.is_replaced = Number(values.replaceDevice);
    }

    try {
      setIsLoading(true);
      const response = await upsertCadenzaSystemService(cadenzaSystemData, status);
      if (response.status === 'success') {
        fetchCadenzaSystems();
      }
    } catch (error) {
      console.error(`Error ${status === 'create' ? 'creating' : 'updating'} cadenza system:`, error);
    } finally {
      setIsLoading(false);
      toggleModal();
    }
  };

  const deviceOptions = ['VPP Box', 'Firewall', 'Energy Meter', 'Switch']

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold text-center mb-6">
          {status === 'edit' ? 'Edit' : 'Create'} Cadenza System
        </h1>
        <Formik
          initialValues={status === 'edit' ? { ...transformedCadenzaSystem, replaceDevice: '' } : initialValues}
          validationSchema={CadenzaSystemFormSchema(status)}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <div className="md:grid grid-cols-2 gap-8 mb-10 mt-2">
                <div className="dr-event-container">
                  <h3 className="mt-4 mb-2 font-bold">Device</h3>
                  <Field
                    as="select"
                    name="device"
                    className="rounded-md w-full py-1 px-2"
                  >
                    <option value="" disabled>
                      Select..
                    </option>
                    {deviceOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="device"
                    component="div"
                    className="text-red-500 mt-1"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">Serial Number</h3>
                  <Field
                    type="text"
                    name="serialNumber"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Serial Number"
                  />
                  <ErrorMessage
                    name="serialNumber"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">Model Number</h3>
                  <Field
                    type="text"
                    name="modelNumber"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Model Number"
                  />
                  <ErrorMessage
                    name="modelNumber"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">Software Version</h3>
                  <Field
                    type="text"
                    name="softwareVersion"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Software Version"
                  />
                  <ErrorMessage
                    name="softwareVersion"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">{status === 'edit' && values.replaceDevice === '1' ? 'Replacement' : 'Installation'} Date</h3>
                  <CustomDatePicker name="replacementInstallationDate" />
                  <ErrorMessage
                    name="replacementInstallationDate"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                {status === 'edit' && (
                  <div>
                    <h3 className="mt-4 mb-4 font-bold">Replace Device</h3>
                    <div role="group" aria-labelledby="replaceDeviceLabel">
                      <label className="font-bold">
                        <Field type="radio" name="replaceDevice" value="1" className="mb-1 mr-1" />
                        Yes
                      </label>

                      <label className="ml-4 font-bold">
                        <Field type="radio" name="replaceDevice" value="0" className="mb-1 mr-1" />
                        No
                      </label>
                    </div>
                    <ErrorMessage
                      name="replaceDevice"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                )}

                <div>
                  <h3 className="mt-4 mb-2 font-bold">Manufacturer</h3>
                  <Field
                    type="text"
                    name="manufacturer"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Manufacturer"
                  />
                  <ErrorMessage
                    name="manufacturer"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex justify-around">
                <button
                  className="rounded-md px-4 py-2 text-sm flex justify-center items-center"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isLoading ? (
                    <Loader classNames="w-5 h-5" />
                  ) : status === 'edit' ? (
                    'Save'
                  ) : (
                    'Create Cadenza System'
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CadenzaSystemForm;
