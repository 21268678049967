const UnderIconBar = ({ now, today, thisMonth, tillNow }) => {
  return (
    <div className="flex flex-row gap-8">
      {[
        { label: 'Now', value: now, unit: 'kW' },
        { label: 'Today', value: today, unit: 'kWh' },
        { label: 'This Month', value: thisMonth, unit: 'MWh' },
        { label: 'Life', value: tillNow, unit: 'MWh' },
      ].map(({ label, value, unit }, index) => (
        <div key={index} className="flex flex-col gap-2 items-center">
          <span className="text-[#0f3052]">{label}</span>
          <span className="border border-black px-2 h-8 flex items-center justify-center min-w-[60px]">
            {value}
          </span>
          <span className="text-[#0f3052]">{unit}</span>
        </div>
      ))}
    </div>
  );
};

export default UnderIconBar;
