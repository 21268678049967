import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons';
import { FiFilter, FiPlus } from 'react-icons/fi';

// Components
import Modal from '../modals/Modal';
import ProgramDetails from '../modals/programs/ProgramDetails';
import ProgramRow from '../tables/ProgramRow';
import SearchFilter from '../controls/SearchFilter';
import ProgramCreateFrom from '../modals/programs/ProgramCreateForm';
import ProgramEditForm from '../modals/programs/ProgramEditForm';
import ProgramDeactivate from '../modals/programs/ProgramDeactivate';
import EnrollmentList from '../modals/programs/EnrollmentList';
import EnrollmentListEnbala from '../modals/programs/EnrollmentListEnbala';

const ProgramContainer = ({ programs: programsData = [], refreshPrograms }) => {
  const [programs, setPrograms] = useState(programsData);
  const [showFilterControls, setShowFilterControls] = useState(false);
  const [showViewModal, setViewModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showDeactivateModal, setDeactivateModal] = useState(false);
  const [showCreateModal, setCreateModal] = useState(false);
  const [showEnrollmentModal, setEnrollmentModal] = useState(false);
  const [showEnrollmentModalEnbala, setEnrollmentModalEnbala] = useState(false);
  const [activeProgram, setActiveProgram] = useState();
  const [search, setSearch] = useState();

  // Hide filter form on click outside of form
  const hideFilterControls = e => {
    if (
      e.target.className !== 'filter-controls' &&
      e.target.parentNode.className !== 'filter-controls' &&
      !e.target.parentNode.closest('.filter-controls') &&
      showFilterControls
    ) {
      setShowFilterControls(false);
    }
  };

  useEffect(() => {
    setPrograms(programsData);
  }, [programsData]);

  useEffect(() => {
    document.addEventListener('mousedown', hideFilterControls);
    document.addEventListener('touchstart', hideFilterControls);
    return () => {
      document.removeEventListener('mousedown', hideFilterControls);
      document.removeEventListener('touchstart', hideFilterControls);
    };
    // NOTE: check deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilterControls]);

  // Manage toggle of modal display
  const toggleViewModal = () => {
    setViewModal(!showViewModal);
  };

  const toggleCreateModal = () => {
    setCreateModal(!showCreateModal);
  };

  const toggleEditModal = () => {
    setEditModal(!showEditModal);
  };

  const toggleDeactivateModal = () => {
    setDeactivateModal(!showDeactivateModal);
  };

  const toggleEnrollmentModal = () => {
    setEnrollmentModal(!showEnrollmentModal);
  };

  const toggleEnrollmentModalEnbala = () => {
    setEnrollmentModalEnbala(!showEnrollmentModalEnbala);
  };

  // Get the details of the target program
  const getActiveProgramDetails = programKey => {
    const activeProgram = programs.find(
      program => program.programKey === programKey);
    return activeProgram;
  };

  // Independent assignment of target program
  const assignActiveProgram = programKey => {
    const activeProgram = getActiveProgramDetails(programKey);
    setActiveProgram(activeProgram);
  };

  // Data update from form filter
  const updateData = data => {
    setPrograms();
    setPrograms(data);
  };

  // Search parameters
  const updateSearchLabel = search => {
    setSearch(search);
  };

  return (
    <main className='flex flex-col items-center p-5 grow mb-6 max-h-85vh'>
      <div
        className='container flex flex-row justify-end items-center mt-2 mb-6 md:mt-2 md:mb-6'>
        {!!search && (
          <div
            className='mr-6 mt-1 border rounded-full px-4 py-1 border-slate-500 color-primary'>
            Searching for {search}
          </div>
        )}
        <div className='mr-4 mt-1 flex items-center relative filter-controls'>
          <button
            type='button'
            onClick={() => {
              setShowFilterControls(!showFilterControls);
            }}
          >
            <IconContext.Provider value={{ color: '#0F3052' }}>
              <FiFilter size={24} />
            </IconContext.Provider>
          </button>
          <div
            className={`${showFilterControls ? 'block' : 'hidden'
              } container-border bg-white py-4 px-6 absolute top-6 right-2 md:top-0 md:right-0 md:mr-10 z-40`}
          >
            <SearchFilter
              initialData={programs}
              updateSearchLabel={updateSearchLabel}
              updateData={updateData}
              params={[
                'ID',
                'program-Name',
                'program-ID',
                'utility-Name',
                'state',
                'program-Type',
                'aggregator',
              ]}
            />
          </div>
        </div>
        <div className='flex items-center'>
          <button
            type='button'
            className='mr-4 mt-1'
            onClick={() => {
              toggleCreateModal();
            }}
          >
            <IconContext.Provider value={{ color: '#0F3052' }}>
              <FiPlus size={24} />
            </IconContext.Provider>
          </button>
        </div>
      </div>
      <section className='container container-border bg-white overflow-auto'>
        <table className='table-auto table-primary w-full relative'>
          <thead className='table-head'>
            <tr>
              <th className='table-head-item'></th>
              <th className='table-head-item'>ID</th>
              <th className='table-head-item'>Program Name</th>
              <th className='table-head-item'>Program ID</th>
              <th className='table-head-item'>Utility Name</th>
              <th className='table-head-item'>State</th>
              <th className='table-head-item'>Program Type</th>
              <th className='table-head-item'>Aggregator</th>
              <th className='table-head-item'>Enrollment</th>
            </tr>
          </thead>
          <tbody>
            {programs?.map(program => (
              <ProgramRow
                program={program}
                key={program.programKey}
                toggleModal={toggleViewModal}
                toggleEditModal={toggleEditModal}
                toggleDeactivateModal={toggleDeactivateModal}
                assignActiveProgram={assignActiveProgram}
                toggleEnrollmentModal={toggleEnrollmentModal}
                toggleEnrollmentModalEnbala={toggleEnrollmentModalEnbala}
              />
            ))}
          </tbody>
        </table>
      </section>

      {!!showCreateModal && (
        <Modal
          toggleModal={toggleCreateModal}
          isSmall={true}
          child={
            <ProgramCreateFrom
              status='create'
              user={activeProgram}
              toggleModal={toggleCreateModal}
              refreshPrograms={refreshPrograms}
            />
          }
        />
      )}

      {!!showEditModal && (
        <Modal
          toggleModal={toggleEditModal}
          isSmall={true}
          child={
            <ProgramEditForm
              status='edit'
              activeProgram={activeProgram}
              toggleModal={toggleEditModal}
              refreshPrograms={refreshPrograms}
            />}
        />
      )}

      {!!showDeactivateModal && activeProgram && (
        <Modal
          toggleModal={toggleDeactivateModal}
          isSmall={true}
          child={
            <ProgramDeactivate
              status='deactivate'
              activeProgram={activeProgram}
              toggleModal={toggleDeactivateModal}
              refreshPrograms={refreshPrograms}
            />}
        />
      )}

      {!!showViewModal && (
        <Modal
          toggleModal={toggleViewModal}
          isSmall={true}
          child={<ProgramDetails program={activeProgram} />}
        />
      )}

      {!!showEnrollmentModal && (
        <Modal
          child={
            <EnrollmentList
              program={activeProgram}
              toggleEnrollmentModal={toggleEnrollmentModal}
            />}
        />
      )}

      {!!showEnrollmentModalEnbala && (
        <Modal
          child={
            <EnrollmentListEnbala
              program={activeProgram}
              toggleEnrollmentModalEnbala={toggleEnrollmentModalEnbala}
            />}
        />
      )}
    </main>
  );
};

export default ProgramContainer;