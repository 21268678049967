import { useCallback } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { IconContext } from 'react-icons';
import { PiFileCsv, PiFilePng } from 'react-icons/pi';
import { CSVLink } from 'react-csv';
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';

const CustomTooltip = ({ active, payload, label, month, year }) => {
  if (active && payload && payload.length) {
    const day = parseInt(label, 10);
    const date = new Date(year, month - 1, day);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'long',
    }).format(date);

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#f4f4f4',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        <p className="label">{`Date: ${formattedDate}`}</p>
        <p className="intro">{`Energy: ${parseFloat(payload[0].value).toFixed(
          2
        )} kWh`}</p>
      </div>
    );
  }
  return null;
};

const MonthlyEnergy = ({ 
  data = {}, 
  month,
  year, 
  assetName, 
  energyName,
}) => {
  const { chartData = [], systemProduction } = data;
  const [getPng, { ref }] = useCurrentPng();

  const daysInMonth = new Date(year, month, 0).getDate();

  let dummyData = [];

  if (!chartData || !Array.isArray(chartData) || chartData.length === 0) {
    for (let i = 1; i <= daysInMonth; i++) {
      dummyData.push({
        day: i,
        energy: 0,
      });
    }
  }

  const dailyData = chartData?.map(item => {
    const date = new Date(item.timestamp);
    const assetDate = new Date(
      date.getTime() - -date.getTimezoneOffset() * 60000
    );

    // Check if the date is valid
    if (isNaN(assetDate)) {
      return '';
    }

    return {
      day: String(assetDate.getDate()).padStart(2, '0'),
      energy: item.power,
    };
  });

  const showData =
    !chartData || !Array.isArray(chartData) || chartData.length === 0
      ? dummyData
      : dailyData;

  const handleDownload = useCallback(async () => {
    const png = await getPng();

    if (png) {
      // Download with FileSaver
      FileSaver.saveAs(
        png,
        `${assetName} ${energyName} monthly ${month}-${year}.png`
      );
    }
  }, [getPng, assetName, year, month, energyName]);

  return (
    <>
      <h1 className="text-center mb-2">System Production: {systemProduction} MWh</h1>
      {
        <ResponsiveContainer>
          <BarChart
            ref={ref}
            width={500}
            height={300}
            data={showData}
            barGap={2}
            barSize={8}
            margin={{
              top: 5,
              right: 30,
              left: 30,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0f3052" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#0f3052" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="day"
              tickFormatter={day => {
                return `${day}`;
              }}
            />
            <YAxis
              label={{
                value: 'kWh',
                position: 'center',
                dx: -30,
                angle: -90,
              }}
            />
            <Tooltip content={<CustomTooltip month={month} year={year} />} />
            <Bar radius={[8, 8, 0, 0]} dataKey="energy" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      }
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CSVLink
          data={showData}
          filename={`${assetName} ${energyName} monthly ${month}-${year}.csv`}
          className="csv-link"
        >
          <button
            type="button"
            className="save-as-csv rounded-md px-1 py-1 text-xs"
            title="Save as CSV"
          >
            <IconContext.Provider value={{ color: '#00000' }}>
              <PiFileCsv size={24} />
            </IconContext.Provider>
          </button>
        </CSVLink>
        <button
          type="button"
          className="save-as-png rounded-md px-1 py-1 text-xs"
          onClick={handleDownload}
          title="Save as PNG"
        >
          <IconContext.Provider value={{ color: '#00000' }}>
            <PiFilePng size={24} />
          </IconContext.Provider>
        </button>
      </div>
    </>
  );
};

export default MonthlyEnergy;
