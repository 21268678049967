const AssetList = props => {
  const results = props.filteredAssets.filter(
    item => item.status === '4 - Not all modbus devices are communicating'
  );

  return (
    <div className="border rounded-lg grow pb-2 px-2 mx-3 md:px-4 md:mx-8 overflow-auto text-sm md:text-base relative">
      <table className="table-auto w-full ">
        <thead className="table-head">
          <tr>
            <th className="table-head-item">Name</th>
            <th className="table-head-item">Status</th>
            <th className="table-head-item">Site Description</th>
            <th className="table-head-item">Account Name</th>
            <th className="table-head-item">Asset Contact</th>
          </tr>
        </thead>
        <tbody>
          {results?.map((item, index) => {
            return (
              <tr
                key={item.assetId + index}
                className="border-b cursor-pointer"
                onClick={() => {
                  props.setTargetDetails(item);
                }}
              >
                <td className="pr-2 py-2 relative">
                  <span
                    type="button"
                    role="button"
                    onClick={() => {
                      props.updateModal(props.modalState, props.modalAction);
                      props.passItem(item);
                    }}
                    onMouseEnter={() => { }}
                  >
                    {item.name}
                  </span>
                </td>
                <td className="pr-2 py-2 ">
                  <div className="flex flex-row items-center align-middle whitespace-nowrap">
                    <span className={`health health-down mr-2`}></span>
                    Fault
                  </div>
                </td>
                <td className="pr-2 py-2">{item.siteDescription}</td>
                <td className="pr-2 py-2">{item.accountName}</td>
                <td className="pr-2 py-2">{item.assetContactName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AssetList;
