// React
import { useRef, useState, useEffect } from 'react';

// Libraries
import Flatpickr from 'react-flatpickr';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { CSVLink } from 'react-csv';

// Services
import {
  fetchHouseLoadDataDWM,
  fetchHouseMeteringDataDWM,
  fetchSecondWattnodeDataDWM,
  fetchYearlyHouseLoadData,
  fetchYearlyHouseMeteringData,
  fetchYearlySecondWattnodeData,
} from '../../services/graphService';
import { fetchSecondWattnodeLayout as fetchSecondWattnodeLayoutService } from '../../services/layoutService';
import { fetchSecondWattnodeHistoricalData as fetchSecondWattnodeHistoricalDataService } from '../../services/historicalDataService';
import { getSolarAssetData } from '../../services/assetService';

// Utils
import prepLayout from '../../utils/PrepLayout';

// Helpers
import {
  calculateAssetTimeRange,
  calculateDateRangeInUTC,
  calculateMonthlyAssetTimeRange,
  calculateMonthRangeInUTC,
  calculateWeeklyAssetTimeRange,
} from '../../helpers/formatDate/calculateAssetTimeRange';
import { transformDataForCSV } from '../../helpers/formatDate/transformDataForCSV';
import { validateAndConvertDatesToUTC } from '../../helpers/formatDate/validateAndConvertDatesToUTC';
import { idToText } from '../../helpers/widget/idToText';
import { formatValue } from '../../helpers/icons/formatValue';
import formatRangeForFileNameLocalTime from '../../helpers/formatDate/formatRangeForFileNameLocalTime';
import getUtcOffsetDifference from '../../helpers/formatDate/convertCoordinatesToUtcOffset';
import getTimezoneFromCoordinates from '../../helpers/formatDate/getTimezoneFromCoordinates';

// Components
import Widget from '../widgets/Widget';
import Loader from '../Loader';
import DailyPower from '../widgets/DailyPower';
import WeeklyPower from '../widgets/WeeklyPower';
import MonthlyEnergy from '../widgets/MonthlyEnergy';
import YearlyEnergy from '../widgets/YearlyEnergy';
import UnderIconBar from '../pageComponents/assetIconComponents/UnderIconBar';
import { ReactComponent as SolarPowerNow } from '../../assets/Solar_Power_Now.svg';
import { ReactComponent as ProductionToday } from '../../assets/Production_Today.svg';
import { ReactComponent as LifetimeProduction } from '../../assets/Lifetime_Production.svg';
import { ReactComponent as MyHouse } from '../../assets/My_House.svg';
import { ReactComponent as HouseMetering } from '../../assets/House_Metering.svg';
import { ReactComponent as SolarEnergy } from '../../assets/Solar_Energy.svg';

// Icons
import { FiX, FiChevronDown } from 'react-icons/fi';
import { BsCalendarEvent } from 'react-icons/bs';
import { PiFileCsv } from 'react-icons/pi';
import { IconContext } from 'react-icons';


const SecondWattnodeDashboard = ({
  assetData: assetDetailsData = {},
  toggleAssetDetails,
  toggleAssetUpsDetails,
  toggleAssetBessDetails,
}) => {
  const {
    name: assetName,
    enableBESS,
    enableUPS,
    secondaryName,
    facilityType,
    EM2Identifier,
  } = assetDetailsData;
  const modalRef = useRef();
  // Init responsive grid layout
  const ResponsiveGridLayout = WidthProvider(Responsive);
  const [layout, setLayout] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [assetData, setAssetData] = useState([]);
  const [secondWattnodeIconsData, setSecondWattnodeIconsData] = useState(null);
  const [isShowExportAssetData, setIsShowExportAssetData] = useState(false);
  const [dailySecondWattnodeData, setDailySecondWattnodeData] = useState([]);
  const [dailyLoadData, setDailyLoadData] = useState([]);
  const [dailyMeteringData, setDailyMeteringData] = useState([]);
  const [weeklySecondWattnodeData, setWeeklySecondWattnodeData] = useState([]);
  const [weeklyLoadData, setWeeklyLoadData] = useState([]);
  const [weeklyMeteringData, setWeeklyMeteringData] = useState([]);
  const [monthlySecondWattnodeData, setMonthlySecondWattnodeData] = useState([]);
  const [monthlyLoadData, setMonthlyLoadData] = useState([]);
  const [monthlyMeteringData, setMonthlyMeteringData] = useState([]);
  const [yearlySecondWattnodeData, setYearlySecondWattnodeData] = useState([]);
  const [yearlyLoadData, setYearlyLoadData] = useState([]);
  const [yearlyMeteringData, setYearlyMeteringData] = useState([]);

  const [IsMonthlySecondWattnodeLoading, setIsMonthlySecondWattnodeLoading] = useState(true);
  const [IsMonthlyLoadLoading, setIsMonthlyLoadLoading] = useState(true);
  const [IsMonthlyMeteringLoading, setIsMonthlyMeteringLoading] =
    useState(true);
  const [isDailySecondWattnodeLoading, setIsDailySecondWattnodeLoading] = useState(true);
  const [isDailyLoadLoading, setIsDailyLoadLoading] = useState(true);
  const [isWeeklySecondWattnodeLoading, setIsWeeklySecondWattnodeLoading] = useState(true);
  const [isWeeklyMeteringLoading, setIsWeeklyMeteringLoading] = useState(true);
  const [isWeeklyLoadLoading, setIsWeeklyLoadLoading] = useState(true);
  const [isDailyMeteringLoading, setIsDailyMeteringLoading] = useState(true);
  const [isYearlySecondWattnodeLoading, setIsYearlySecondWattnodeLoading] = useState(true);
  const [isYearlyMeteringLoading, setIsYearlyMeteringLoading] = useState(true);
  const [isYearlyLoadLoading, setIsYearlyLoadLoading] = useState(true);
  const [isAssetHistoricalDataLoading, setIsAssetHistoricalDataLoading] =
    useState(false);
  const [isSecondWattnodeIconsDataLoading, setIsSecondWattnodeIconsDataLoading] = useState(true);
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const additionalLoaders = isFirstTimeLoading
    ? isDailyLoadLoading ||
      isDailyMeteringLoading ||
      isDailySecondWattnodeLoading ||
      isWeeklyLoadLoading ||
      isWeeklyMeteringLoading ||
      isWeeklySecondWattnodeLoading ||
      isYearlyLoadLoading ||
      isYearlyMeteringLoading ||
      isYearlySecondWattnodeLoading ||
      IsMonthlyLoadLoading ||
      IsMonthlyMeteringLoading ||
      IsMonthlySecondWattnodeLoading
    : false;

  const [secondWattnodeDailyStartDate, setSecondWattnodeDailyStartDate] = useState('');
  const [secondWattnodeDailyEndDate, setSecondWattnodeDailyEndDate] = useState('');
  const [loadDailyStartDate, setLoadDailyStartDate] = useState('');
  const [loadDailyEndDate, setLoadDailyEndDate] = useState('');
  const [secondWattnodeWeeklyStartDate, setSecondWattnodeWeeklyStartDate] = useState('');
  const [secondWattnodeWeeklyEndDate, setSecondWattnodeWeeklyEndDate] = useState('');
  const [meteringWeeklyStartDate, setMeteringWeeklyStartDate] = useState('');
  const [meteringWeeklyEndDate, setMeteringWeeklyEndDate] = useState('');
  const [loadWeeklyStartDate, setLoadWeeklyStartDate] = useState('');
  const [loadWeeklyEndDate, setLoadWeeklyEndDate] = useState('');
  const [meteringDailyStartDate, setMeteringDailyStartDate] = useState('');
  const [meteringDailyEndDate, setMeteringDailyEndDate] = useState('');
  const [assetDataStartDateTime, setAssetDataStartDateTime] = useState('');
  const [assetDataEndDateTime, setAssetDataEndDateTime] = useState('');
  const [backupLoadAssetStartDate, setPowerAssetStartDate] = useState();
  const [backupLoadAssetEndDate, setPowerAssetEndDate] = useState();
  const [meteringAssetStartDate, setMeteringAssetStartDate] = useState();
  const [meteringAssetEndDate, setMeteringAssetEndDate] = useState();
  const [loadAssetStartDate, setLoadAssetStartDate] = useState();
  const [loadAssetEndDate, setLoadAssetEndDate] = useState();
  const [powerAssetStartWeekDate, setPowerAssetStartWeekDate] = useState('');
  const [powerAssetEndWeekDate, setPowerAssetEndWeekDate] = useState('');
  const [meteringAssetStartWeekDate, setMeteringAssetStartWeekDate] =
    useState('');
  const [meteringAssetEndWeekDate, setMeteringAssetEndWeekDate] = useState('');
  const [loadAssetStartWeekDate, setLoadAssetStartWeekDate] = useState('');
  const [loadAssetEndWeekDate, setLoadAssetEndWeekDate] = useState('');
  const now = new Date();
  const assetDifferenceFromUtc = getUtcOffsetDifference(
    assetDetailsData?.gpsCoordinates?.latitude,
    assetDetailsData?.gpsCoordinates?.longitude
  );
  const assetTimezone = getTimezoneFromCoordinates(
    assetDetailsData?.gpsCoordinates?.latitude,
    assetDetailsData?.gpsCoordinates?.longitude
  );
  const nowAssetTime = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  );
  const startOfDay = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  );
  startOfDay.setHours(0, 0, 0, 0);
  const endOfDay = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  );
  endOfDay.setHours(23, 59, 59, 999);
  const [currentSecondWattnodeYearOnly, setCurrentSecondWattnodeYearOnly] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentMeteringYearOnly, setCurrentMeteringYearOnly] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentLoadYearOnly, setCurrentLoadYearOnly] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentSecondWattnodeYear, setCurrentSecondWattnodeYear] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentSecondWattnodeMonth, setCurrentSecondWattnodeMonth] = useState(
    nowAssetTime.getMonth() + 1
  );
  const [currentMeteringYear, setCurrentMeteringYear] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentMeteringMonth, setCurrentMeteringMonth] = useState(
    nowAssetTime.getMonth() + 1
  );
  const [currentLoadYear, setCurrentLoadYear] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentLoadMonth, setCurrentLoadMonth] = useState(
    nowAssetTime.getMonth() + 1
  );

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    fetchInitialChartsData();
    fetchLayoutData();
    fetchSecondWattnodeIconsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line
  const scrollToPreviousPosition = () => {
    setTimeout(function () {
      modalRef.current?.scrollTo(0, scrollPosition);
    }, 0);
  };

  useEffect(() => {
    scrollToPreviousPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToPreviousPosition]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleScroll = () => {
    const position = modalRef.current.scrollTop;
    setScrollPosition(position);
  };

  // Fetch layout based on EM2 existance
  const fetchLayoutData = async () => {
    try {
      const secondWattnodeLayout = await fetchSecondWattnodeLayoutService(
        EM2Identifier
      );
      setLayout(secondWattnodeLayout);
    } catch (error) {
      console.error('Error fetching second wattnode layout:', error);
    }
  };

  // Fetch icons data
  const fetchSecondWattnodeIconsData = async () => {
    try {
      const secondWattnodeData = await getSolarAssetData(assetName);
      setSecondWattnodeIconsData(secondWattnodeData);
    } catch (error) {
      console.error('Error fetching SecondWattnode data:', error);
    } finally {
      setIsSecondWattnodeIconsDataLoading(false);
    }
  };

  // Fetch initial charts data
  const fetchInitialChartsData = async () => {
    const {
      startToUTC,
      endToUTC,
      startOfDay,
      endOfDay,
      startAssetTime,
      endAssetTime,
    } = calculateAssetTimeRange(assetDifferenceFromUtc);

    const {
      startToUTCWeek,
      endToUTCWeek,
      startOfWeek,
      endOfWeek,
      startAssetTimeWeek,
      endAssetTimeWeek,
    } = calculateWeeklyAssetTimeRange(assetDifferenceFromUtc);

    const { startOfMonth, endOfMonth } = calculateMonthlyAssetTimeRange();

    if (EM2Identifier) {
      try {
        const dailyPowerResult = await fetchSecondWattnodeDataDWM(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailySecondWattnodeData(dailyPowerResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setPowerAssetStartDate(startAssetTime);
        setPowerAssetEndDate(endAssetTime);
        setSecondWattnodeDailyStartDate(startOfDay);
        setSecondWattnodeDailyEndDate(endOfDay);
        setIsDailySecondWattnodeLoading(false);
      }
    }
    if (EM2Identifier) {
      try {
        const dailyLoadResult = await fetchHouseLoadDataDWM(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailyLoadData(dailyLoadResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoadAssetStartDate(startAssetTime);
        setLoadAssetEndDate(endAssetTime);
        setLoadDailyStartDate(startOfDay);
        setLoadDailyEndDate(endOfDay);
        setIsDailyLoadLoading(false);
      }
    }
    try {
      const dailyMeteringPowerResult = await fetchHouseMeteringDataDWM(
        assetName,
        startToUTC,
        endToUTC,
        'daily'
      );
      setDailyMeteringData(dailyMeteringPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setMeteringAssetStartDate(startAssetTime);
      setMeteringAssetEndDate(endAssetTime);
      setMeteringDailyStartDate(startOfDay);
      setMeteringDailyEndDate(endOfDay);
      setIsDailyMeteringLoading(false);
    }
    try {
      const weeklyMeteringResult = await fetchHouseMeteringDataDWM(
        assetName,
        startToUTCWeek,
        endToUTCWeek,
        'daily'
      );
      setWeeklyMeteringData(weeklyMeteringResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setMeteringAssetStartWeekDate(startAssetTimeWeek);
      setMeteringAssetEndWeekDate(endAssetTimeWeek);
      setMeteringWeeklyStartDate(startOfWeek);
      setMeteringWeeklyEndDate(endOfWeek);
      setIsWeeklyMeteringLoading(false);
    }
    if (EM2Identifier) {
      try {
        const weeklyLoadResult = await fetchHouseLoadDataDWM(
          assetName,
          startToUTCWeek,
          endToUTCWeek,
          'daily'
        );
        setWeeklyLoadData(weeklyLoadResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoadAssetStartWeekDate(startAssetTimeWeek);
        setLoadAssetEndWeekDate(endAssetTimeWeek);
        setLoadWeeklyStartDate(startOfWeek);
        setLoadWeeklyEndDate(endOfWeek);
        setIsWeeklyLoadLoading(false);
      }
    }
    if (EM2Identifier) {
      try {
        const weeklyPowerResult = await fetchSecondWattnodeDataDWM(
          assetName,
          startToUTCWeek,
          endToUTCWeek,
          'daily'
        );
        setWeeklySecondWattnodeData(weeklyPowerResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setPowerAssetStartWeekDate(startAssetTimeWeek);
        setPowerAssetEndWeekDate(endAssetTimeWeek);
        setSecondWattnodeWeeklyStartDate(startOfWeek);
        setSecondWattnodeWeeklyEndDate(endOfWeek);
        setIsWeeklySecondWattnodeLoading(false);
      }
    }
    if (EM2Identifier) {
      try {
        const monthlyPowerResult = await fetchSecondWattnodeDataDWM(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlySecondWattnodeData(monthlyPowerResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setIsMonthlySecondWattnodeLoading(false);
      }
    }
    try {
      const monthlyMeteringResult = await fetchHouseMeteringDataDWM(
        assetName,
        startOfMonth,
        endOfMonth,
        'monthly'
      );
      setMonthlyMeteringData(monthlyMeteringResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsMonthlyMeteringLoading(false);
    }
    if (EM2Identifier) {
      try {
        const monthlyLoadResult = await fetchHouseLoadDataDWM(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlyLoadData(monthlyLoadResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setIsMonthlyLoadLoading(false);
      }
    }
    if (EM2Identifier) {
      try {
        const yearlyPowerResult = await fetchYearlySecondWattnodeData(
          assetName,
          currentSecondWattnodeYearOnly
        );
        setYearlySecondWattnodeData(yearlyPowerResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setIsYearlySecondWattnodeLoading(false);
      }
    }
    try {
      const yearlyMeteringResult = await fetchYearlyHouseMeteringData(
        assetName,
        currentMeteringYearOnly
      );
      setYearlyMeteringData(yearlyMeteringResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsYearlyMeteringLoading(false);
    }
    if (EM2Identifier) {
      try {
        const yearlyLoadResult = await fetchYearlyHouseLoadData(
          assetName,
          currentLoadYearOnly
        );
        setYearlyLoadData(yearlyLoadResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setIsYearlyLoadLoading(false);
      }
    }

    setIsFirstTimeLoading(false);
  };

  // Date filter functionality for daily/weekly charts
  const dateFilter = async (element, startDateParam) => {
    if (!element || !startDateParam) return;
    let endDateParam;
    if (
      element === 'dailySecondWattnodePower' ||
      element === 'dailyHouseMeteringPower' ||
      element === 'dailyHouseLoadPower'
    ) {
      endDateParam = new Date(startDateParam);
      endDateParam.setHours(23, 59, 59, 999);
    }

    if (
      element === 'weeklySecondWattnodePower' ||
      element === 'weeklyHouseMeteringPower' ||
      element === 'weeklyHouseLoadPower'
    ) {
      endDateParam = new Date(startDateParam);
      endDateParam.setDate(endDateParam.getDate() + 6);
      endDateParam.setHours(0, 0, 0, 0);
    }

    const { startToUTC, endToUTC, startOfDayUTC, endOfDayUTC } =
      calculateDateRangeInUTC(
        startDateParam,
        endDateParam,
        assetDifferenceFromUtc
      );

    handleScroll();

    if (element === 'dailySecondWattnodePower') {
      try {
        setIsDailySecondWattnodeLoading(true);
        const dailyPowerResult = await fetchSecondWattnodeDataDWM(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailySecondWattnodeData(dailyPowerResult);
      } catch (error) {
        console.error('Error fetching backup load data:', error);
      } finally {
        setSecondWattnodeDailyStartDate(startDateParam);
        setSecondWattnodeDailyEndDate(endDateParam);
        setPowerAssetStartDate(startOfDayUTC);
        setPowerAssetEndDate(endOfDayUTC);
        setIsDailySecondWattnodeLoading(false);
      }
    }
    if (element === 'dailyHouseLoadPower') {
      try {
        setIsDailyLoadLoading(true);
        const dailyLoadResult = await fetchHouseLoadDataDWM(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailyLoadData(dailyLoadResult);
      } catch (error) {
        console.error('Error fetching backup load data:', error);
      } finally {
        setLoadDailyStartDate(startDateParam);
        setLoadDailyEndDate(endDateParam);
        setLoadAssetStartDate(startOfDayUTC);
        setLoadAssetEndDate(endOfDayUTC);
        setIsDailyLoadLoading(false);
      }
    }
    if (element === 'dailyHouseMeteringPower') {
      try {
        setIsDailyMeteringLoading(true);
        const dailyMeteringPowerResult = await fetchHouseMeteringDataDWM(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailyMeteringData(dailyMeteringPowerResult);
      } catch (error) {
        console.error('Error fetching backup load data:', error);
      } finally {
        setMeteringDailyStartDate(startDateParam);
        setMeteringDailyEndDate(endDateParam);
        setMeteringAssetStartDate(startOfDayUTC);
        setMeteringAssetEndDate(endOfDayUTC);
        setIsDailyMeteringLoading(false);
      }
    }

    if (element === 'weeklySecondWattnodePower') {
      try {
        setIsWeeklySecondWattnodeLoading(true);
        const weeklyPowerResult = await fetchSecondWattnodeDataDWM(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setWeeklySecondWattnodeData(weeklyPowerResult);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setSecondWattnodeWeeklyStartDate(startDateParam);
        setSecondWattnodeWeeklyEndDate(endDateParam);
        setPowerAssetStartWeekDate(startOfDayUTC);
        setPowerAssetEndWeekDate(endOfDayUTC);
        setIsWeeklySecondWattnodeLoading(false);
      }
    }

    if (element === 'weeklyHouseMeteringPower') {
      try {
        setIsWeeklyMeteringLoading(true);
        const weeklyMeteringResult = await fetchHouseMeteringDataDWM(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setWeeklyMeteringData(weeklyMeteringResult);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setMeteringWeeklyStartDate(startDateParam);
        setMeteringWeeklyEndDate(endDateParam);
        setMeteringAssetStartWeekDate(startOfDayUTC);
        setMeteringAssetEndWeekDate(endOfDayUTC);
        setIsWeeklyMeteringLoading(false);
      }
    }
    if (element === 'weeklyHouseLoadPower') {
      try {
        setIsWeeklyLoadLoading(true);
        const weeklyLoadResult = await fetchHouseLoadDataDWM(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setWeeklyLoadData(weeklyLoadResult);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoadWeeklyStartDate(startDateParam);
        setLoadWeeklyEndDate(endDateParam);
        setLoadAssetStartWeekDate(startOfDayUTC);
        setLoadAssetEndWeekDate(endOfDayUTC);
        setIsWeeklyLoadLoading(false);
      }
    }
  };

  // Month filter for monthly charts
  const monthFilter = async (element, year, month) => {
    if (!element || !year || !month) return;

    const { startOfMonth, endOfMonth } = calculateMonthRangeInUTC(year, month);

    handleScroll();
    if (element === 'monthlySecondWattnodePower') {
      try {
        setIsMonthlySecondWattnodeLoading(true);
        const monthlyPowerResult = await fetchSecondWattnodeDataDWM(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlySecondWattnodeData(monthlyPowerResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setCurrentSecondWattnodeYear(year);
        setCurrentSecondWattnodeMonth(month);
        setIsMonthlySecondWattnodeLoading(false);
      }
    }
    if (element === 'monthlyHouseMeteringPower') {
      try {
        setIsMonthlyMeteringLoading(true);
        const monthlyMeteringResult = await fetchHouseMeteringDataDWM(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlyMeteringData(monthlyMeteringResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setCurrentMeteringYear(year);
        setCurrentMeteringMonth(month);
        setIsMonthlyMeteringLoading(false);
      }
    }
    if (element === 'monthlyHouseLoadPower') {
      try {
        setIsMonthlyLoadLoading(true);
        const monthlyLoadResult = await fetchHouseLoadDataDWM(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlyLoadData(monthlyLoadResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setCurrentLoadYear(year);
        setCurrentLoadMonth(month);
        setIsMonthlyLoadLoading(false);
      }
    }
  };

  // Year filter for early charts
  const yearFilter = async (element, year) => {
    if (!element || !year) return;

    handleScroll();
    if (element === 'yearlySecondWattnodePower') {
      try {
        setIsYearlySecondWattnodeLoading(true);
        const yearlySecondWattnodeResult = await fetchYearlySecondWattnodeData(
          assetName,
          year
        );
        setYearlySecondWattnodeData(yearlySecondWattnodeResult);
      } catch (error) {
        console.error('Error fetching yearly secondWattnode data:', error);
      } finally {
        setCurrentSecondWattnodeYearOnly(year);
        setIsYearlySecondWattnodeLoading(false);
      }
    }
    if (element === 'yearlyHouseMeteringPower') {
      try {
        setIsYearlyMeteringLoading(true);
        const yearlyMeteringResult = await fetchYearlyHouseMeteringData(
          assetName,
          year
        );
        setYearlyMeteringData(yearlyMeteringResult);
      } catch (error) {
        console.error('Error fetching yearly secondWattnode data:', error);
      } finally {
        setCurrentMeteringYearOnly(year);
        setIsYearlyMeteringLoading(false);
      }
    }
    if (element === 'yearlyHouseLoadPower') {
      try {
        setIsYearlyLoadLoading(true);
        const yearlyLoadResult = await fetchYearlyHouseLoadData(
          assetName,
          year
        );
        setYearlyLoadData(yearlyLoadResult);
      } catch (error) {
        console.error('Error fetching yearly secondWattnode data:', error);
      } finally {
        setCurrentLoadYearOnly(year);
        setIsYearlyLoadLoading(false);
      }
    }
  };

  const handleLayoutChange = layouts => {
    if (windowDimensions.width > 1024) {
      prepLayout(
        layouts,
        layout,
        `${
          EM2Identifier
            ? 'secondWattnodeDashboardLayout'
            : 'secondWattnodeDashboardLayoutWithoutEM2'
        }`
      );
    }
  };

  // Historical data export
  const getHistoricalData = async () => {
    const validatedDates = validateAndConvertDatesToUTC(
      assetDataStartDateTime,
      assetDataEndDateTime,
      assetDifferenceFromUtc
    );

    if (!validatedDates) {
      return;
    }

    const { formattedStartDateUTC, formattedEndDateUTC } = validatedDates;

    try {
      setIsAssetHistoricalDataLoading(true);
      const historicalData = await fetchSecondWattnodeHistoricalDataService(
        assetName,
        formattedStartDateUTC,
        formattedEndDateUTC
      );
      setAssetData(historicalData);
    } catch (error) {
      console.error('Error fetching second wattnode historical data:', error);
    } finally {
      setIsAssetHistoricalDataLoading(false);
    }
  };

  const handleStartDateTimeChange = ([date]) => {
    setScrollPosition(0);
    setAssetDataStartDateTime(date.toString());
  };

  const handleEndDateTimeChange = ([date]) => {
    setScrollPosition(0);
    setAssetDataEndDateTime(date.toString());
  };

  const toggleShowExportAssetData = () => {
    setScrollPosition(0);
    setIsShowExportAssetData(!isShowExportAssetData);
  };

  const handleCalendarClose = () => {
    getHistoricalData();
  };

  const rangeForFileName = formatRangeForFileNameLocalTime(
    new Date(assetDataStartDateTime),
    new Date(assetDataEndDateTime)
  );

  const transformedData = transformDataForCSV(
    assetData,
    assetDifferenceFromUtc,
    ['myHouse', 'solarEnergy', 'houseMetering']
  );

  // Values for icons
  const secondWattnodePowerNow = formatValue(secondWattnodeIconsData?.solarPowerNow);
  const productionToday = formatValue(secondWattnodeIconsData?.productionToday);
  const lifetimeProduction = formatValue(secondWattnodeIconsData?.lifetimeProduction);
  const myHouseNow = formatValue(secondWattnodeIconsData?.myHouseNow);
  const myHouseToday = formatValue(secondWattnodeIconsData?.myHouseToday);
  const myHouseThisMonth = formatValue(secondWattnodeIconsData?.myHouseThisMonth);
  const myHouseTillNow = formatValue(secondWattnodeIconsData?.myHouseThisTillNow);
  const secondWattnodeEnergyNow = formatValue(secondWattnodeIconsData?.solarEnergyNow);
  const secondWattnodeEnergyToday = formatValue(secondWattnodeIconsData?.solarEnergyToday);
  const secondWattnodeEnergyThisMonth = formatValue(
    secondWattnodeIconsData?.solarEnergyThisMonth
  );
  const secondWattnodeEnergyTillNow = formatValue(
    secondWattnodeIconsData?.solarEnergyThisTillNow
  );
  const houseMeteringNow = formatValue(secondWattnodeIconsData?.houseMeteringNow);
  const houseMeteringToday = formatValue(secondWattnodeIconsData?.houseMeteringToday);
  const houseMeteringThisMonth = formatValue(
    secondWattnodeIconsData?.houseMeteringThisMonth
  );
  const houseMeteringTillNow = formatValue(
    secondWattnodeIconsData?.houseMeteringThisTillNow
  );

  return (
    <div className="modal-container modal-container-alt-full flex justify-center items-center px-5 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-sm md:text-base cursor-pointer">
      <div
        ref={modalRef}
        className="container container-border mx-auto border-0 pb-6 relative flex flex-col justify-start w-full bg-white outline-none focus:outline-none cursor-auto"
      >
        <header className="flex flex-col justify-center py-7 md:sticky top-0 bg-white z-50 drop-shadow relative lg:flex-row">
          <h1 className="text-2xl font-bold text-center flex-grow sm:mt-2 lg:mt-0">
            Real-Time Data: {assetName ? assetName : 'Asset'}
          </h1>
          <div className="flex flex-wrap md:flex-row flex-col justify-center align-center gap-1 sm:mt-2 lg:mt-0 md:items-center lg:mr-14">
            {enableUPS && (
              <button
                className="rounded-md px-1 py-2 text-sm flex justify-center items-center md:mr-2 !min-w-[120px]"
                onClick={() => {
                  toggleAssetDetails();
                  toggleAssetUpsDetails();
                }}
              >
                UPS
              </button>
            )}
            {enableBESS && (
              <button
                className="rounded-md px-1 py-2 text-sm flex justify-center items-center md:mr-2 !min-w-[120px]"
                onClick={() => {
                  toggleAssetDetails();
                  toggleAssetBessDetails();
                }}
              >
                BESS
              </button>
            )}
            <button
              className="rounded-md px-1 py-2 text-sm flex justify-center items-center !min-w-[120px] !bg-gray-500"
              onClick={() => {}}
              disabled
            >
              {secondaryName || 'Secondary'}
            </button>
          </div>
          <span
            className="absolute cursor-pointer top-8 right-4"
            type="button"
            role="button"
            onClick={() => toggleAssetDetails()}
          >
            <FiX size={28} />
          </span>
        </header>
        {isSecondWattnodeIconsDataLoading || additionalLoaders ? (
          <div className="w-full h-full flex justify-center items-center">
            <Loader classNames="w-32 h-32" />
          </div>
        ) : (
          <div className="p-6 flex flex-col justify-between">
            <div className="flex flex-row justify-end">
              {isShowExportAssetData && (
                <div className="flex flex-col gap-1 md:flex-row md:items-center md:gap-2 lg:flex-wrap xl:flex-nowrap mb-4">
                  <p className="mr-2 font-medium text-lg">
                    {`Export ${secondaryName || 'Secondary'} historical data:`}
                  </p>
                  <div className=" flex items-center justify-between md:justify-center">
                    <p className="mr-1">Start:</p>
                    <div className=" flex items-center justify-around w-44 h-10 relative cursor-pointer">
                      <Flatpickr
                        name={`${assetName}-start`}
                        placeholder="mm.dd.yyyy hh:mm"
                        className="calendar-input"
                        onChange={handleStartDateTimeChange}
                        onClose={handleCalendarClose}
                        options={{
                          dateFormat: 'm.d.Y H:i',
                          enableTime: true,
                          maxDate: nowAssetTime,
                          disableMobile: true,
                        }}
                      />
                      <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                      <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                    </div>
                  </div>

                  <div className="flex items-center justify-between md:justify-center">
                    <p className="mr-1">End:</p>
                    <div className="flex items-center justify-around w-44 h-10 relative cursor-pointer">
                      <Flatpickr
                        placeholder="mm.dd.yyyy hh:mm"
                        className="calendar-input"
                        name={`${assetName}-end`}
                        onChange={handleEndDateTimeChange}
                        onClose={handleCalendarClose}
                        options={{
                          dateFormat: 'm.d.Y H:i',
                          enableTime: true,
                          minDate: assetDataStartDateTime
                            ? new Date(assetDataStartDateTime)
                            : null,
                          maxDate: nowAssetTime,
                          disableMobile: true,
                        }}
                      />
                      <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                      <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                    </div>
                  </div>

                  {isAssetHistoricalDataLoading ? (
                    <Loader classNames="w-5 h-5 mr-2 ml-1" />
                  ) : (
                    <CSVLink
                      data={transformedData}
                      filename={`${assetName} SOLAR historical data ${rangeForFileName}.csv`}
                      className="csv-link mr-2 ml-1"
                    >
                      <button
                        type="button"
                        className="save-as-csv rounded-md px-1 py-1 text-xs"
                        title="Save as CSV"
                        disabled={
                          !assetDataStartDateTime || !assetDataEndDateTime
                        }
                      >
                        <IconContext.Provider value={{ color: '#00000' }}>
                          <PiFileCsv size={24} />
                        </IconContext.Provider>
                      </button>
                    </CSVLink>
                  )}
                </div>
              )}
              <FiChevronDown
                size={18}
                className={`cursor-pointer transition-transform ${
                  isShowExportAssetData ? 'rotate-180' : null
                }`}
                onClick={toggleShowExportAssetData}
                title="Export asset historical data"
              />
            </div>

            <div
              className={`flex flex-wrap md:justify-${
                EM2Identifier ? 'between' : 'evenly'
              } justify-center items-center md:mx-20 md:mb-4`}
            >
              {EM2Identifier && (
                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center h-[40px] sm:h-[50px] font-medium text-xl">
                    My {facilityType || 'Site'}
                  </h2>
                  <div className="h-[150px] w-[150px]">
                    <MyHouse />
                  </div>
                  <div>
                    <UnderIconBar
                      now={myHouseNow}
                      today={myHouseToday}
                      thisMonth={myHouseThisMonth}
                      tillNow={myHouseTillNow}
                    />
                  </div>
                </div>
              )}

              {EM2Identifier && (
                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center h-[40px] sm:h-[50px] font-medium text-xl">
                    {secondaryName || 'Secondary'} Energy
                  </h2>
                  <div className="h-[150px] w-[150px]">
                    <SolarEnergy />
                  </div>
                  <div>
                    <UnderIconBar
                      now={secondWattnodeEnergyNow}
                      today={secondWattnodeEnergyToday}
                      thisMonth={secondWattnodeEnergyThisMonth}
                      tillNow={secondWattnodeEnergyTillNow}
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center h-[40px] sm:h-[50px] font-medium text-xl">
                  {facilityType || 'Site'} Metering
                </h2>
                <div className="h-[150px] w-[150px]">
                  <HouseMetering />
                </div>
                <div>
                  <UnderIconBar
                    now={houseMeteringNow}
                    today={houseMeteringToday}
                    thisMonth={houseMeteringThisMonth}
                    tillNow={houseMeteringTillNow}
                  />
                </div>
              </div>
            </div>

            {EM2Identifier && (
              <div
                className={`flex flex-wrap md:justify-between justify-center items-center mt-4 mx-32 mb-8 gap-4`}
              >
                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center font-medium text-xl md:h-[40px]">
                    {secondaryName || 'Secondary'} Power Now
                  </h2>
                  <div className="h-[150px] w-[150px]">
                    <SolarPowerNow />
                  </div>
                  <div className="mt-10">
                    <p className="text-center text-sm font-medium sm:text-base">
                      {secondWattnodePowerNow} kW
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center font-medium text-xl md:h-[40px]">
                    {secondaryName || 'Secondary'} Production Today
                  </h2>
                  <div className="h-[150px] w-[150px]">
                    <ProductionToday />
                  </div>
                  <div className="mt-10">
                    <p className="text-center text-sm font-medium sm:text-base">
                      {productionToday} kWh
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                    Lifetime Production
                  </h2>
                  <div className="h-[150px] w-[150px]">
                    <LifetimeProduction />
                  </div>
                  <div className="mt-10">
                    <p className="text-center text-sm font-medium sm:text-base">
                      {lifetimeProduction} MWh
                    </p>
                  </div>
                </div>
              </div>
            )}
            <ResponsiveGridLayout
              className="layout"
              layouts={{ lg: layout }}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
              rowHeight={30}
              margin={[15, 15]}
              measureBeforeMount={true}
              useCSSTransforms={false}
              draggableHandle=".draggableHandle"
              draggableCancel=".dashboardNav--cancel"
              onLayoutChange={handleLayoutChange}
            >
              {layout?.map(element => {
                const title = idToText(element.i);

                // Inject child components dynamically
                let componentSwitch;
                let filter;
                let widgetDateFilter;
                let widgetDateRange;
                let createEvent;
                let createEventAndFilters;
                let widgetDateTimeFilter;
                let widgetYearFilter;
                let year;
                let month;
                let timezone;
                let dailyFilter;
                let assetDifferenceFromUtcInMinutes;
                let additionalTitle;
                let widgetMonthFilter;
                let isMaxDayWeek;

                assetDifferenceFromUtcInMinutes = assetDifferenceFromUtc;

                switch (element.i) {
                  case 'dailySecondWattnodePower':
                    additionalTitle = `Daily ${
                      secondaryName || 'Secondary'
                    } Power`;
                    dailyFilter = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: secondWattnodeDailyStartDate,
                      end: secondWattnodeDailyEndDate,
                    };
                    componentSwitch = isDailySecondWattnodeLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <DailyPower
                        assetName={assetName}
                        chartStartDate={backupLoadAssetStartDate}
                        chartEndDate={backupLoadAssetEndDate}
                        data={dailySecondWattnodeData}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        energyName={secondaryName || 'Secondary'}
                        energyType="Secondary"
                      />
                    );
                    break;
                  case 'weeklySecondWattnodePower':
                    additionalTitle = `Weekly ${
                      secondaryName || 'Secondary'
                    } Power`;
                    isMaxDayWeek = true;
                    dailyFilter = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: secondWattnodeWeeklyStartDate,
                      end: secondWattnodeWeeklyEndDate,
                    };
                    componentSwitch = isWeeklySecondWattnodeLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <WeeklyPower
                        assetName={assetName}
                        data={weeklySecondWattnodeData}
                        chartStartDate={powerAssetStartWeekDate}
                        chartEndDate={powerAssetEndWeekDate}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        energyName={secondaryName || 'Secondary'}
                        energyType="Secondary"
                      />
                    );
                    break;
                  case 'monthlySecondWattnodePower':
                    additionalTitle = `Monthly ${
                      secondaryName || 'Secondary'
                    } Energy`;
                    year = currentSecondWattnodeYear;
                    month = currentSecondWattnodeMonth;
                    widgetMonthFilter = monthFilter;
                    componentSwitch = IsMonthlySecondWattnodeLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <MonthlyEnergy
                        data={monthlySecondWattnodeData}
                        month={month}
                        year={year}
                        assetName={assetName}
                        energyName={secondaryName || 'Secondary'}
                      />
                    );
                    break;
                  case 'yearlySecondWattnodePower':
                    additionalTitle = `Yearly ${
                      secondaryName || 'Secondary'
                    } Energy`;
                    widgetYearFilter = yearFilter;
                    year = currentSecondWattnodeYearOnly;
                    componentSwitch = isYearlySecondWattnodeLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <YearlyEnergy
                        year={year}
                        data={yearlySecondWattnodeData}
                        assetName={assetName}
                        energyName={secondaryName || 'Secondary'}
                      />
                    );
                    break;

                  case 'dailyHouseLoadPower':
                    additionalTitle = `Daily ${
                      facilityType || 'Site'
                    } Load Power`;
                    dailyFilter = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: loadDailyStartDate,
                      end: loadDailyEndDate,
                    };
                    componentSwitch = isDailyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <DailyPower
                        assetName={assetName}
                        chartStartDate={loadAssetStartDate}
                        chartEndDate={loadAssetEndDate}
                        data={dailyLoadData}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        energyName={`${facilityType || 'Site'} Load`}
                        energyType="Load"
                      />
                    );
                    break;
                  case 'weeklyHouseLoadPower':
                    additionalTitle = `Weekly ${
                      facilityType || 'Site'
                    } Load Power`;
                    dailyFilter = true;
                    isMaxDayWeek = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: loadWeeklyStartDate,
                      end: loadWeeklyEndDate,
                    };
                    componentSwitch = isWeeklyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <WeeklyPower
                        assetName={assetName}
                        data={weeklyLoadData}
                        chartStartDate={loadAssetStartWeekDate}
                        chartEndDate={loadAssetEndWeekDate}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        energyName={`${facilityType || 'Site'} Load`}
                        energyType="Load"
                      />
                    );
                    break;
                  case 'monthlyHouseLoadPower':
                    additionalTitle = `Monthly ${
                      facilityType || 'Site'
                    } Load Energy`;
                    year = currentLoadYear;
                    month = currentLoadMonth;
                    widgetMonthFilter = monthFilter;
                    componentSwitch = IsMonthlyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <MonthlyEnergy
                        data={monthlyLoadData}
                        month={month}
                        year={year}
                        assetName={assetName}
                        energyName={`${facilityType || 'Site'} Load`}
                      />
                    );
                    break;
                  case 'yearlyHouseLoadPower':
                    additionalTitle = `Yearly ${
                      facilityType || 'Site'
                    } Load Energy`;
                    widgetYearFilter = yearFilter;
                    year = currentLoadYearOnly;
                    componentSwitch = isYearlyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <YearlyEnergy
                        year={year}
                        data={yearlyLoadData}
                        assetName={assetName}
                        energyName={`${facilityType || 'Site'} Load`}
                      />
                    );
                    break;

                  case 'dailyHouseMeteringPower':
                    additionalTitle = `Daily ${
                      facilityType || 'Site'
                    } Metering Power`;
                    dailyFilter = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: meteringDailyStartDate,
                      end: meteringDailyEndDate,
                    };
                    componentSwitch = isDailyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <DailyPower
                        assetName={assetName}
                        chartStartDate={meteringAssetStartDate}
                        chartEndDate={meteringAssetEndDate}
                        data={dailyMeteringData}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        energyName={`${facilityType || 'Site'} Metering`}
                        energyType="Metering"
                      />
                    );
                    break;
                  case 'weeklyHouseMeteringPower':
                    additionalTitle = `Weekly ${
                      facilityType || 'Site'
                    } Metering Power`;
                    dailyFilter = true;
                    isMaxDayWeek = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: meteringWeeklyStartDate,
                      end: meteringWeeklyEndDate,
                    };
                    componentSwitch = isWeeklyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <WeeklyPower
                        assetName={assetName}
                        data={weeklyMeteringData}
                        chartStartDate={meteringAssetStartWeekDate}
                        chartEndDate={meteringAssetEndWeekDate}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        energyName={`${facilityType || 'Site'} Metering`}
                        energyType="Metering"
                      />
                    );
                    break;
                  case 'monthlyHouseMeteringPower':
                    additionalTitle = `Monthly ${
                      facilityType || 'Site'
                    } Metering Energy`;
                    year = currentMeteringYear;
                    month = currentMeteringMonth;
                    widgetMonthFilter = monthFilter;
                    componentSwitch = IsMonthlyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <MonthlyEnergy
                        data={monthlyMeteringData}
                        month={month}
                        year={year}
                        assetName={assetName}
                        energyName={`${facilityType || 'Site'} Metering`}
                      />
                    );
                    break;
                  case 'yearlyHouseMeteringPower':
                    additionalTitle = `Yearly ${
                      facilityType || 'Site'
                    } Metering Energy`;
                    widgetYearFilter = yearFilter;
                    year = currentMeteringYearOnly;
                    componentSwitch = isYearlyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <YearlyEnergy
                        year={year}
                        data={yearlyMeteringData}
                        assetName={assetName}
                        energyName={`${facilityType || 'Site'} Metering`}
                      />
                    );
                    break;

                  default:
                    return null;
                }

                return (
                  <div
                    key={element.i}
                    className="container-border grid-component__container overflow-hidden"
                  >
                    <Widget
                      title={additionalTitle ? additionalTitle : title}
                      id={element.i}
                      hasFilter={filter}
                      dateFilter={widgetDateFilter}
                      dateTimeFilter={widgetDateTimeFilter}
                      yearFilter={widgetYearFilter}
                      monthFilter={widgetMonthFilter}
                      dateRange={widgetDateRange}
                      year={year}
                      month={month}
                      isMaxDayWeek={isMaxDayWeek}
                      dailyFilter={dailyFilter}
                      child={componentSwitch}
                      hasCreateEvent={createEvent}
                      hasCreateEventAndFilters={createEventAndFilters}
                      assetTimezone={timezone}
                      assetDifferenceFromUtc={assetDifferenceFromUtcInMinutes}
                      assetName={assetName}
                    />
                  </div>
                );
              })}
            </ResponsiveGridLayout>
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondWattnodeDashboard;
