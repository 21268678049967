export const getTicks = (data, dateDifferenceInDays) => {
  try {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    if (dateDifferenceInDays <= 1) {
      const tickInterval = Math.ceil(data.length / 24);
      return Array.from(
        { length: 24 },
        (_, i) => data[i * tickInterval]?.timestamp
      );
    } else if (dateDifferenceInDays === 7) {
      const ticks = [];

    
      const startDate = new Date(data[0].timestamp);
      const endDate = new Date(data[data.length - 1].timestamp);
  
      for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
        ticks.push(new Date(d).toISOString());
      }
  
   
      return [...new Set(ticks)];
    } else {
      const ticksCount = dateDifferenceInDays + 1;
      const interval = Math.ceil(data.length / ticksCount);

      // Calculate ticks evenly distributed over the selected date range
      const intermediateTicks = Array.from(
        { length: ticksCount - 1 },
        (_, i) => data[Math.min((i + 1) * interval, data.length - 1)]?.timestamp
      );

      return [
        data[0]?.timestamp,
        ...intermediateTicks,
        data[data.length - 1]?.timestamp,
      ];
    }
  } catch (error) {
    console.error('Error in getTicks:', error);
    return [];
  }
};
