import { useEffect, useState } from 'react';

// Services
import { getProgramList as getProgramListService } from '../services/programService';

// Components
import ProgramContainer from '../components/containers/DR-ProgramsContainer';
import Loader from '../components/Loader';

const DRPrograms = () => {
  const [programs, setPrograms] = useState([]);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const programList = await getProgramListService();
      setPrograms(programList);
    } catch (error) {
      console.error('Error fetching program list:', error);
    } finally {
      setIsProgramsLoading(false);
    }
  };

  return (
    isProgramsLoading ? (
      <div className="loader-global-container">
        <Loader classNames="w-32 h-32" />
      </div>
    ) : (
      <ProgramContainer
        programs={programs}
        refreshPrograms={fetchData}
      />
    )
  )
};

export default DRPrograms;