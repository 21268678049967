import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// Access
import accessControl from '../helpers/accessControl';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

// For Grid Power and Battery Voltage/Current
export const fetchChartHistoricalData = async (
  assetName,
  startDate,
  endDate,
  propName
) => {
  if (propName === 'gridPower' && !accessControl.isPlatformAdministrator()) {
    return;
  }

  try {
    const response = await api.post(
      `/device/asset-data/${assetName}/historical`,
      {
        startDate,
        endDate,
        propName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data.assetHistoricalData;
  } catch (error) {
    Notify.warning(`Failed to fetch ${propName} data`, notifyOptions);
    throw error;
  }
};

export const fetchPerformanceData = async (assetName, startDate, endDate) => {
  try {
    const response = await api.post(
      `/device/dps-performance`,
      {
        startDate,
        endDate,
        assetName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data.dpsPerformanceData;
  } catch (error) {
    Notify.warning(`Failed to fetch performance data`, notifyOptions);
    throw error;
  }
};

export const fetchMaxPowerData = async (assetName, year) => {
  try {
    const response = await api.post(
      `/device/max-power`,
      {
        year,
        assetName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data.maxPowerData;
  } catch (error) {
    Notify.warning(`Failed to fetch max power data`, notifyOptions);
    throw error;
  }
};

export const fetchBackupLoadData = async (assetName, startDate, endDate) => {
  try {
    const response = await api.post(
      `/device/ups-backup-load`,
      {
        startDate,
        endDate,
        assetName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data.backupLoadData;
  } catch (error) {
    Notify.warning(`Failed to fetch backup load data`, notifyOptions);
    throw error;
  }
};

export const fetchAccountPowerData = async (startDate, endDate) => {
  try {
    const response = await api.post(
      `/device/power`,
      {
        startDate,
        endDate,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data.powerData.power;
  } catch (error) {
    Notify.warning(`Failed to fetch account power data`, notifyOptions);
    throw error;
  }
};

export const fetchAccountEnergyData = async (startDate, endDate) => {
  try {
    const response = await api.post(
      `/device/energy`,
      {
        startDate,
        endDate,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return response.data.energyData.energy;
  } catch (error) {
    Notify.warning(`Failed to fetch account energy data`, notifyOptions);
    throw error;
  }
};

// fetch data for day, week and month based on timePeriod
export const fetchSecondWattnodeDataDWM = async (assetName, startDate, endDate, timePeriod) => {
  try {
    const response = await api.post(
      `/device/second-wattnode/${timePeriod}`,
      { startDate, endDate, assetName },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return {
      chartData: response?.data[`${timePeriod}SecondWattnodePowerData`],
      systemProduction: response?.data?.systemProduction?.toFixed(2),
    };
  } catch (error) {
    Notify.warning(`Failed to fetch account solar data`, notifyOptions);
    throw error;
  }
};

// fetch data for day, week and month based on timePeriod
export const fetchHouseMeteringDataDWM = async (assetName, startDate, endDate, timePeriod) => {
  try {
    const response = await api.post(
      `/device/house-metering/${timePeriod}`,
      { startDate, endDate, assetName },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return {
      chartData: response?.data[`${timePeriod}HouseMeteringPowerData`],
      systemProduction: response?.data?.systemProduction?.toFixed(2),
    };
  } catch (error) {
    Notify.warning(`Failed to fetch account solar data`, notifyOptions);
    throw error;
  }
};

// fetch data for day, week and month based on timePeriod
export const fetchHouseLoadDataDWM = async (assetName, startDate, endDate, timePeriod) => {
  try {
    const response = await api.post(
      `/device/house-load/${timePeriod}`,
      { startDate, endDate, assetName },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return {
      chartData: response?.data[`${timePeriod}HouseLoadPowerData`],
      systemProduction: response?.data?.systemProduction?.toFixed(2),
    };
  } catch (error) {
    Notify.warning(`Failed to fetch account houe load data`, notifyOptions);
    throw error;
  }
};

export const fetchYearlySecondWattnodeData = async (assetName, currentYear) => {
  try {
    const response = await api.post(
      `/device/second-wattnode/yearly`,
      {
        currentYear,
        assetName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return {
      chartData: response?.data?.yearlySecondWattnodePowerData,
      systemProduction: response?.data?.systemProduction?.toFixed(2),
    };
  } catch (error) {
    Notify.warning(`Failed to fetch yearly second wattnode data`, notifyOptions);
    throw error;
  }
}

export const fetchYearlyHouseMeteringData = async (assetName, currentYear) => {
  try {
    const response = await api.post(
      `/device/house-metering/yearly`,
      {
        currentYear,
        assetName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return {
      chartData: response?.data?.yearlyHouseMeteringPowerData,
      systemProduction: response?.data?.systemProduction?.toFixed(2),
    };
  } catch (error) {
    Notify.warning(`Failed to fetch yearly house metering data`, notifyOptions);
    throw error;
  }
}

export const fetchYearlyHouseLoadData = async (assetName, currentYear) => {
  try {
    const response = await api.post(
      `/device/house-load/yearly`,
      {
        currentYear,
        assetName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    );

    return {
      chartData: response?.data?.yearlyHouseLoadPowerData,
      systemProduction: response?.data?.systemProduction?.toFixed(2),
    };
  } catch (error) {
    Notify.warning(`Failed to fetch yearly house load data`, notifyOptions);
    throw error;
  }
}