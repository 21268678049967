import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
} from 'react-router-dom';

// Pages
import Home from './pages/Home';
import Accounts from './pages/Accounts';
import Users from './pages/Users';
import Login from './pages/Login';
import Verify from './pages/Verify';
import About from './pages/About';

// Components
import Nav from './components/Nav';
import RequireAuth from './components/RequireAuth';

// Assets
import { ReactComponent as Logo } from './assets/logo-cadenza.svg';
import Logout from './pages/Logout';
import { Role } from './constants/role.enum';
import Profile from './pages/Profile';
import useAuth from './hooks/useAuth';
import PrivateRoute from './components/PrivateRoute';
import DRPrograms from './pages/DR-Programs';

function App() {
  const { auth } = useAuth();
  return (
    <article className="main-wrapper flex flex-col min-h-screen">
      <Router>
        <header className="flex justify-center py-2 px-5 bg-white drop-shadow z-50">
          <div className="container flex flex-col items-center md:flex-row md:justify-between">
            <Link to="/">
              <Logo width={150} />
            </Link>

            {/* Menu */}
            {auth.user ? <Nav /> : ''}
          </div>
        </header>

        <Routes>
          <Route
            path="/"
            element={<PrivateRoute redirectTo="/login" children={<Outlet />} />}
          >
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    Role.PLATFORM_ADMINISTRATOR,
                    Role.CLIENT_ADMINISTRATOR,
                    Role.CLIENT_USER,
                    Role.RESIDENT,
                  ]}
                />
              }
            >
              <Route path="/" element={<Home />} />
              <Route path="/users" element={<Users />} />
              <Route path="/profile" element={<Profile />} />
              <Route
                element={
                  <RequireAuth allowedRoles={[Role.PLATFORM_ADMINISTRATOR]} />
                }
              >
                <Route path="/dr-programs" element={<DRPrograms />} />
              </Route>
              <Route
                element={
                  <RequireAuth allowedRoles={[Role.PLATFORM_ADMINISTRATOR]} />
                }
              >
                <Route path="/about" element={<About />} />
              </Route>
            </Route>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    Role.PLATFORM_ADMINISTRATOR,
                    Role.CLIENT_ADMINISTRATOR,
                  ]}
                />
              }
            >
              <Route path="/accounts" element={<Accounts />} />
            </Route>
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/verify" element={<Verify />} />
        </Routes>
      </Router>
    </article>
  );
}

export default App;
