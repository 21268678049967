// Calendar
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

// Icons
import { BsCalendarEvent } from 'react-icons/bs';

// Helpers
import getArrOfStartReadyEvent from '../../helpers/formatDate/getArrOfStartReadyEvent';
import getFilteredEvents from '../../helpers/formatDate/getFilteredEvents';

export default function CalendarEvents({
  setFilteredEvents,
  originalEvents = [],
}) {
  const datesEventReady = getArrOfStartReadyEvent(originalEvents);

  // Handlers
  const onChangeCalendar = ([date]) => {
    const filteredEventsList = getFilteredEvents(originalEvents, date);
    setFilteredEvents(filteredEventsList);
  };

  const onResetFilter = () => {
    setFilteredEvents(originalEvents);
  };

  return (
    <>
      <div className="dashboardNav--cancel flex items-center gap-1 md:gap-2 text-base md:mr-3 ">
        <p className=" text-xs md:text-base">View event dates:</p>
        <div className="calendarEvents-container flex items-center justify-center w-8 h-8 relative">
          <Flatpickr
            className="w-full h-full opacity-0 absolute bg-black z-50"
            onChange={onChangeCalendar}
            options={{
              enableTime: false,
              enable: [...datesEventReady],
              dateFormat: 'Y-m-d',
            }}
          />
          <BsCalendarEvent className="custom-icon " />
        </div>
        <button
          className="rounded-md px-2 py-2 md:px-4 md:py-2 text-xs md:text-sm text-white bg-[#0f3052]"
          onClick={onResetFilter}
        >
          Clear filters
        </button>
      </div>
    </>
  );
}