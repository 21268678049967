import React, { useEffect, useState } from 'react';

// Services
import { getAccounts as getAccountsService } from '../services/accountService';
import { getHomeDashboardData as getHomeDashboardDataService } from '../services/homeDataService';

// Components
import HomeContainer from '../components/containers/HomeContainer';
import { FilterProvider } from '../context/FilterContext';
import Loader from '../components/Loader';
import SingleBESSContainer from '../components/containers/singleBessContainer';
import accessControl from '../helpers/accessControl';

function Home() {
  const [accounts, setAccounts] = useState();
  const [dashboardData, setDashboardData] = useState(null);
  const [dashboardError, setDashboardError] = useState(null);
  const [accountsError, setAccountsError] = useState(null);
  const [isAccountsDataLoading, setIsAccountsDataLoading] = useState(true);
  const [isDashboardDataLoading, setIsDashboardDataLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
    fetchAccounts();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const dashboardData = await getHomeDashboardDataService();
      setDashboardData(dashboardData);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setDashboardError(error);
    } finally {
      setIsDashboardDataLoading(false);
    }
  };

  const fetchAccounts = async () => {
    try {
      const accountsList = await getAccountsService();
      setAccounts(accountsList);
    } catch (error) {
      console.error('Error fetching accounts data:', error);
      setAccountsError(error);
    } finally {
      setIsAccountsDataLoading(false);
    }
  };

  return (
    <div>
      {dashboardError || accountsError ? (
        <p>Error fetching data</p>
      ) : isDashboardDataLoading || isAccountsDataLoading ? (
        <div className="loader-global-container">
          <Loader classNames="w-32 h-32" />
        </div>
      ) : (
        <FilterProvider>
          {dashboardData?.assetList?.length > 1 || accessControl.isPlatformAdministrator() ? (
            <HomeContainer
              dashboardData={dashboardData}
              accountsData={accounts}
              getDashboardData={fetchDashboardData}
            />
          ) : (
            <SingleBESSContainer assetData={dashboardData?.assetList[0]} />
          )}
        </FilterProvider>
      )}
    </div>
  );
}

export default Home;
