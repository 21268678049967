// React
import React, { useEffect, useState } from 'react';

// Services
import { 
  getAssetList as getAssetListService,
  getEnrollmentList as getEnrollmentListService,
 } from '../../../services/programService';

// Components
import EnrollAssetToProgram from './EnrollAssetToProgram';
import UnenrollAssetFromProgram from './UnenrollAssetFromProgram';
import Loader from '../../Loader';
import Modal from '../Modal';

// Helpers
import accessControl from '../../../helpers/accessControl';
import getUtcStringFormat from '../../../helpers/formatDate/getUtcStringFormat';

// Icons
import { FiX } from 'react-icons/fi';

const EnrollmentList = ({
  program,
  toggleEnrollmentModal
}) => {
  const [enrollmentList, setEnrollmentList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [showEnrollProgramModal, setShowEnrollProgramModal] = useState(false);
  const [showUnenrollProgramModal, setShowUnenrollProgramModal] = useState(false);
  const [activeAssets, setActiveAsset] = useState([]);
  const [checkboxState, setCheckboxState] = useState({});
  const [isAssetListLoading, setIsAssetListLoading] = useState(true);
  const [isEnrollmentListLoading, setIsEnrollmentListLoading] = useState(true);

  useEffect(() => {
    getEnrollmentList();
    fetchAssetList(program.programKey, program.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAssetList = async (programKey, state) => {
    try {
      const assetList = await getAssetListService(programKey, state);
      setAssetList(assetList);
    } catch (error) {
      console.error('Error fetching asset list:', error);
    } finally {
      setIsAssetListLoading(false);
    }
  }

  const getEnrollmentList = async () => {
    try {
      const enrollmentList = await getEnrollmentListService(program.programID);
      setEnrollmentList(enrollmentList);
    } catch (error) {
      console.error('Error fetching enrollment list:', error);
    } finally {
      setIsEnrollmentListLoading(false);
    }
  };

  const toggleEnrollModal = () => {
    setShowEnrollProgramModal(!showEnrollProgramModal);
  };

  const toggleUnenrollModal = () => {
    setShowUnenrollProgramModal(!showUnenrollProgramModal);
  };

  const enrollProgram = () => {
    setShowEnrollProgramModal(true);
  };

  const unenrollProgram = () => {
    setShowUnenrollProgramModal(true);
  };

  const checkboxClickHandler = (bessName) => {
    if (activeAssets.includes(bessName)) {
      setActiveAsset(activeAssets.filter(asset => asset !== bessName));
    } else {
      setActiveAsset([...activeAssets, bessName]);
    }
  };

  const toggleCheckbox = (bessName) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [bessName]: !prevState[bessName],
    }));
  };

  const enrollmentListClickHandler = (key) => {
    fetchAssetList(key, program.state)
  };

  return (
    <div
      className='modal-container modal-container-alt-full flex justify-center items-center px-5 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-sm md:text-base cursor-pointer'>
      <div
        className='container container-border mx-auto border-0 pb-6 relative flex flex-col justify-start w-full bg-white outline-none focus:outline-none cursor-auto'
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <header className='py-7 sticky top-0 bg-white z-50 drop-shadow'>
          <span
            className='absolute top-6 right-4'
            type='button'
            role='button'
            onClick={() => toggleEnrollmentModal()}
          >
            <FiX size={28} />
          </span>
          <h1 className='text-2xl font-bold text-center'>Enrollment List</h1>
        </header>
        {(isAssetListLoading || isEnrollmentListLoading) ? (
          <div className='w-full h-full flex justify-center items-center'>
            <Loader classNames='w-32 h-32' />
          </div>
        ) : (
          <div className='p-6 pt-0 flex flex-col justify-between'>
            {(accessControl.isPlatformAdministrator() ? (
              <div className='flex flex-col justify-end mt-4'>
                <h2 className='text-lg font-bold mb-2'>Enrollments For Program</h2>
                <div
                  className='table-container'
                >
                  <table
                    className="table-auto w-full border rounded-lg">
                    <thead className="table-head">
                      <tr>
                        <th className="table-head-item">ID</th>
                        <th className="table-head-item">Program Name</th>
                        <th className="table-head-item">Utility Name</th>
                        <th className="table-head-item">State</th>
                        <th className="table-head-item">Program Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={program.programKey}>
                        <td className="pr-2 py-2">{program.programKey}</td>
                        <td className="pr-2 py-2">{program.programName}</td>
                        <td className="pr-2 py-2">{program.utilityName}</td>
                        <td className="pr-2 py-2">{program.state}</td>
                        <td className="pr-2 py-2">{program.programType}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>) : '')}

            {(accessControl.isPlatformAdministrator() ? (
              <div className='flex flex-col justify-end mt-6'>
                <h2 className='text-lg font-bold mb-2'>Enrollment List</h2>
                <div
                  className='table-container'
                  style={enrollmentList.length > 4 ? {
                    maxHeight: 'calc(11 * 40px)',
                    overflowY: 'auto',
                  } : {}}
                >
                  <table
                    className="table-auto w-full border rounded-lg">
                    <thead className="table-head">
                      <tr>
                        <th className="table-head-item">Email</th>
                        <th className="table-head-item">Status</th>
                        <th className="table-head-item">Devices</th>
                        <th className="table-head-item">Provider</th>
                        <th className="table-head-item">Last Updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enrollmentList.map((enrollment) => (
                        <tr
                          key={`${enrollment.lastUpdatedTime}_${enrollment.programKey}_${new Date().getTime()}`}
                          className="cursor-pointer"
                          onClick={() => enrollmentListClickHandler(enrollment.programKey)}
                        >
                          <td className="pr-2 py-2">{enrollment.email}</td>
                          <td className="pr-2 py-2">{enrollment.programStatus}</td>
                          <td className="pr-2 py-2">{enrollment.numberOfDevices}</td>
                          <td className="pr-2 py-2">{enrollment.provider}</td>
                          <td className="pr-2 py-2">{getUtcStringFormat(enrollment.lastUpdatedTime)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>) : '')}

            {(accessControl.isPlatformAdministrator() ? (
              <div className='flex flex-col justify-end mt-6'>
                <h2 className='text-lg font-bold mb-2'>Asset List</h2>
                <div
                  className='table-container'
                  style={assetList.length > 5 ? {
                    maxHeight: 'calc(5 * 40px)',
                    overflowY: 'auto',
                  } : {}}
                >
                  <table className="table-auto w-full border rounded-lg">
                    <thead className="table-head">
                      <tr>
                        <th className="table-head-item"></th>
                        <th className="table-head-item">Asset Name</th>
                        <th className="table-head-item">BESS Serial Number</th>
                        <th className="table-head-item">Site Description</th>
                        <th className="table-head-item">Model Number</th>
                        <th className="table-head-item">Enrollment Status</th>
                        <th className="table-head-item">Device Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assetList.map(item => {
                        return (
                          <tr
                            key={item.bessSerialNumber}
                          >
                            <td className="pr-2 py-2">
                              <input
                                type="checkbox"
                                className="cursor-pointer"
                                onClick={() => checkboxClickHandler(item.bessSerialNumber)}
                                checked={!!checkboxState[item.bessSerialNumber]}
                                onChange={() => toggleCheckbox(item.bessSerialNumber)}
                              />
                            </td>
                            <td className="pr-2 py-2">{item.bessName}</td>
                            <td className="pr-2 py-2">{item.bessSerialNumber}</td>
                            <td className="pr-2 py-2">{item.siteDescription}</td>
                            <td className="pr-2 py-2">{item.modelNumber}</td>
                            <td className="pr-2 py-2">{item.enrollmentStatus}</td>
                            <td className="pr-2 py-2">{item.status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className='flex justify-center mt-4'>
                  <button
                    style={{ width: '6vw', minHeight: '2rem', marginRight: '1rem' }}
                    className='rounded-md px-2 py-1 text-sm'
                    onClick={() => enrollProgram(program.programKey)}
                  >
                    Enroll
                  </button>
                  <button
                    style={{ width: '6vw', minHeight: '2rem', marginLeft: '1rem' }}
                    className='rounded-md px-2 py-1 text-sm'
                    onClick={() => unenrollProgram(program.programKey)}
                  >
                    Unenroll
                  </button>
                </div>

              </div>) : '')}
          </div>
        )}
      </div>

      {showEnrollProgramModal && (
        <Modal
          toggleModal={toggleEnrollModal}
          isSmall={true}
          child={
            <EnrollAssetToProgram
              activeAssets={activeAssets}
              activeProgram={program}
              toggleModal={toggleEnrollModal}
              setActiveAsset={setActiveAsset}
              toggleCheckbox={toggleCheckbox}
              checkboxState={checkboxState}
            />
          }
        />
      )}

      {showUnenrollProgramModal && (
        <Modal
          toggleModal={toggleUnenrollModal}
          isSmall={true}
          child={
            <UnenrollAssetFromProgram
              activeAssets={activeAssets}
              activeProgram={program}
              toggleModal={toggleUnenrollModal}
              setActiveAsset={setActiveAsset}
              toggleCheckbox={toggleCheckbox}
              checkboxState={checkboxState}
            />
          }
        />
      )}

    </div>
  );
};

export default EnrollmentList;
