// React
import React, { useState } from 'react';

// Components
import Loader from '../../Loader';

// Services
import { enrollBessToVpp as enrollBessToVppService } from '../../../services/vppService';

export default function EnrollBessToVpp({
  toggleModal,
  enrollmentStatus,
  fetchAssetListByVppId,
  selectedBess,
}) {
  const [isEnrolling, setIsEnrolling] = useState(false);

  // Enroll/unenroll BESS to VPP
  const enrollBessToVpp = async () => {
    setIsEnrolling(true);
    try {
      await enrollBessToVppService(selectedBess.name, enrollmentStatus === "Enrolled" ? false : true);
      fetchAssetListByVppId();
    } catch (error) {
      console.error(`Error ${enrollmentStatus === "Enrolled" ? 'unenrolling BESS from VPP' : 'enrolling BESS to VPP'}`, error);
    } finally {
      setIsEnrolling(false);
    }
  };

  // Handlers
  const handleEnrollConfirm = () => {
    enrollBessToVpp();
    toggleModal();
  };

  const handleReject = () => {
    toggleModal();
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <h1 className="text-3xl font-bold text-center py-28">
        {`Are you sure you want to ${enrollmentStatus === "Enrolled" ? 'unenroll' : 'enroll'} 
        "${selectedBess.name}" ${enrollmentStatus === "Enrolled" ? 'from' : 'to'} VPP?`}
      </h1>
      <div className="flex flex-col justify-center gap-4 md:flex-row">
        <button
          className="rounded-md py-2 text-sm flex justify-center"
          onClick={handleEnrollConfirm}
        >
          {isEnrolling ? <Loader classNames="w-5 h-5" /> : 'Yes'}
        </button>
        <button
          className="rounded-md py-2 text-sm"
          onClick={handleReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
