// React
import React, { useState, useEffect } from 'react';

// Services
import { fetchCadenzaSystems as fetchCadenzaSystemscService } from '../../../services/cadenzaSystemService';

// Components
import CadenzaSystems from '../../tables/CadenzaSystems';
import CadenzaSystemForm from './CadenzaSystemForm';
import Modal from '../Modal';
import Loader from '../../Loader';

// Icons
import { FiPlus } from 'react-icons/fi';
import { IconContext } from 'react-icons';

const VppInformation = ({ assetName }) => {
  const [cadenzaSystems, setCadenzaSystems] = useState([]);
  const [isCadenzaSystemsDataLoading, setIsCadenzaSystemsDataLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    fetchCadenzaSystemsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch cadenza systems
  const fetchCadenzaSystemsData = async () => {
    try {
      const systems = await fetchCadenzaSystemscService(assetName);
      setCadenzaSystems(systems);
    } catch (error) {
      console.error('Error fetching cadenza systems data:', error);
    } finally {
      setIsCadenzaSystemsDataLoading(false);
    }
  };

  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-2xl font-bold text-center mb-6">System Details</h2>

      <div className="mb-6 w-full">
        <div className='flex items-center gap-1'>
          <h3 className="text-lg font-semibold mb-2">Cadenza Systems</h3>
          <button
            className="add-event-button mb-2"
            onClick={() => {
              toggleCreateModal();
            }}
          >
            <IconContext.Provider value={{ color: '#0F3052' }}>
              <FiPlus />
            </IconContext.Provider>
          </button>
        </div>
        {isCadenzaSystemsDataLoading ? (
          <div className='w-full h-full flex justify-center items-center'>
            <Loader classNames='w-32 h-32' />
          </div>
        ) : (
          <CadenzaSystems
            cadenzaSystems={cadenzaSystems}
            assetName={assetName}
            fetchCadenzaSystems={fetchCadenzaSystemsData}
          />
        )}
      </div>

      {!!showCreateModal && (
        <Modal
          toggleModal={toggleCreateModal}
          isSmall={true}
          child={
            <CadenzaSystemForm
              status="create"
              assetName={assetName}
              toggleModal={toggleCreateModal}
              fetchCadenzaSystems={fetchCadenzaSystemsData}
            />
          }
        />
      )}
    </div>
  )
};

export default VppInformation;