import { Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import CalculateFleetStatus from '../../helpers/widget/calculateFleetStatus';

function FleetStatus({assets = []}) {
  const statuses = CalculateFleetStatus(assets);

  const connection = [
    { name: 'Online Fleet', value: statuses.connection.online },
    { name: 'Offline Fleet', value: statuses.connection.offline },
    { name: '', value: 0 },
  ];
  const state = [
    { name: 'Charging', value: statuses.state.charging },
    { name: 'Discharging', value: statuses.state.discharging },
    { name: 'Idle', value: statuses.state.idle },
  ];
  const warnings = [
    { name: 'All Clear', value: statuses.warnings.healthy },
    { name: 'Warning', value: statuses.warnings.unhealthy },
    { name: 'Fault', value: statuses.warnings.down },
  ];

  const CONNECTIONCOLORS = ['#88C971', '#0f3052'];
  const STATECOLORS = ['#0089b6', '#0F3052', '#88C971'];
  const WARNINGCOLORS = ['#88C971', '#f68c1e', '#C93545'];

  return (
    <div className="flex flex-row h-full">
      <div className="w-1/3">
        <h1 className="text-center font-bold">Connection</h1>
        <ResponsiveContainer width="100%">
          <PieChart className="custom-pie">
            <Pie
              data={connection}
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
              startAngle={180}
              endAngle={0}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {connection.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={CONNECTIONCOLORS[index % CONNECTIONCOLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              wrapperStyle={{ bottom: '50%', textAlign: 'center' }}
              iconSize={0}
              layout="vertical"
              verticalAlign="bottom"
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-1/3">
        <h1 className="text-center font-bold">State</h1>
        <ResponsiveContainer width="100%">
          <PieChart className="custom-pie">
            <Pie
              data={state}
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
              startAngle={180}
              endAngle={0}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {state.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={STATECOLORS[index % STATECOLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              wrapperStyle={{ bottom: '50%' }}
              iconSize={0}
              layout="vertical"
              verticalAlign="bottom"
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-1/3">
        <h1 className="text-center font-bold">Warnings/Faults</h1>
        <ResponsiveContainer width="100%">
          <PieChart className="custom-pie">
            <Pie
              data={warnings}
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
              startAngle={180}
              endAngle={0}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {warnings.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={WARNINGCOLORS[index % WARNINGCOLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              wrapperStyle={{ bottom: '50%' }}
              iconSize={0}
              layout="vertical"
              verticalAlign="bottom"
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default FleetStatus;
