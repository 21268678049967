const DefaultSingleBessLayout = [
  {
    i: 'BESSPerformance',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 12,
    h: 8,
    x: 0,
    y: 0,
  },
  {
    i: 'chargingEvents',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 12,
    h: 8,
    x: 0,
    y: 0,
  },
  {
    i: 'dischargingEvents',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 12,
    h: 8,
    x: 0,
    y: 0,
  },
  {
    i: 'scheduledDR',
    maxW: 40,
    maxH: 40,
    minH: 6,
    minW: 2,
    w: 12,
    h: 8,
    x: 0,
    y: 0,
  },
  {
    i: 'dailySecondWattnodePower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 0,
    __v: 0,
  },
  {
    i: 'weeklySecondWattnodePower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 0,
    __v: 0,
  },
  {
    i: 'monthlySecondWattnodePower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 8,
    __v: 0,
  },
  {
    i: 'yearlySecondWattnodePower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 8,
    __v: 0,
  },
  {
    i: 'dailyHouseLoadPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 16,
    __v: 0,
  },
  {
    i: 'weeklyHouseLoadPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 16,
    __v: 0,
  },
  {
    i: 'monthlyHouseLoadPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 24,
    __v: 0,
  },
  {
    i: 'yearlyHouseLoadPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 24,
    __v: 0,
  },
  {
    i: 'dailyHouseMeteringPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 32,
    __v: 0,
  },
  {
    i: 'weeklyHouseMeteringPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 32,
    __v: 0,
  },
  {
    i: 'monthlyHouseMeteringPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 40,
    __v: 0,
  },
  {
    i: 'yearlyHouseMeteringPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 40,
    __v: 0,
  },
];

module.exports = DefaultSingleBessLayout;
