import React, { useState } from 'react';

// Components
import Modal from '../modals/Modal';
import EnrollBessToVpp from '../modals/vppEnrollments/EnrollBessToVpp';

// Helpers
import getAssetStatus from '../../helpers/widget/getAssetStatus';
import getHealthClass from '../../helpers/widget/getHealthClass';
import accessControl from '../../helpers/accessControl';

const Besses = (
  {
    filteredBesses,
    enrollmentStatus,
    fetchAssetListByVppId,
  }
) => {
  const [selectedBess, setSelectedBess] = useState(null);

  const [showEnrollModal, setShowEnrollModal] = useState(false);

  const toggleEnrollModal = () => {
    setShowEnrollModal(!showEnrollModal);
  };

  const clickHandler = (item) => {
    setSelectedBess(item);
    toggleEnrollModal();
  };

  return (
    <div
      className="border rounded-lg overflow-auto text-sm md:text-base relative w-full"
      style={filteredBesses.length > 6 ? {
        maxHeight: 'calc(7 * 43px)',
        overflowY: 'auto',
      } : {}}
    >
      <table className="table-auto w-full">
        <thead className="table-head">
          <tr>
            <th className="table-head-item">Asset Name</th>
            <th className="table-head-item">BESS Serial Number</th>
            <th className="table-head-item text-center">Battery SOC</th>
            <th className="table-head-item text-center">Status</th>
            <th className="table-head-item">Site Description</th>
            <th className="table-head-item">Account Name</th>
            <th className="table-head-item">Account Contact</th>
            <th className="table-head-item">Model Number</th>
            <th className="table-head-item">Gateway SN</th>
            {accessControl.isPlatformAdministrator() &&
              <th className="table-head-item"></th>
            }
          </tr>
        </thead>
        <tbody>
          {filteredBesses?.map(item => {
            return (
              <tr
                key={item.assetId}
                className="border-b cursor-pointer"
              >
                <td className="pr-2 py-2">{item.bessName}</td>
                <td className="pr-2 py-2 relative">
                  <span type="button" role="button">
                    {item.name}
                  </span>
                </td>
                <td className="pr-2 py-2 text-center">
                  {item.batterySOC?.toFixed(2)}
                </td>
                <td className="pr-2 py-2">
                  <div className="flex flex-row justify-center items-center align-middle whitespace-nowrap h-full">
                    <span
                      className={`health ${getHealthClass(
                        item.errorState
                      )} mr-2`}
                    ></span>
                    {getAssetStatus(item.errorState)}
                  </div>
                </td>
                <td className="pr-2 py-2">{item.siteDescription}</td>
                <td className="pr-2 py-2">{item.accountName}</td>
                <td className="pr-2 py-2">{`${item.assetContactFirstName} ${item.assetContactLastName}`}</td>
                <td className="pr-2 py-2">{item.modelNumber}</td>
                <td className="pr-2 py-2">{item.gatewaySerialNumber}</td>
                {accessControl.isPlatformAdministrator() &&
                  <td>
                    <button
                      style={{ minWidth: '6vw', minHeight: '2rem' }}
                      className='rounded-md px-2 py-1 text-sm'
                      onClick={() => clickHandler(item)}
                    >
                      {enrollmentStatus === "Enrolled" ? "Unenroll" : "Enroll"}
                    </button>
                  </td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>

      {!!showEnrollModal && (
        <Modal
          toggleModal={toggleEnrollModal}
          isSmall={true}
          child={
            <EnrollBessToVpp
              selectedBess={selectedBess}
              toggleModal={toggleEnrollModal}
              fetchAssetListByVppId={fetchAssetListByVppId}
              enrollmentStatus={enrollmentStatus}
            />
          }
        />
      )}
    </div>
  );
};

export default Besses;
