import React, { useState } from 'react';

//Services
import { enrollAssetToEnbala as enrollAssetToEnbalaService } from '../../../services/programService';

export default function EnrollAssetToEnbala({
  toggleModal,
  assetName,
  fetchAssetList,
}) {
  const [isLoading, setIsLoading] = useState(false);

  // Handlers
  const handleEnrollConfirm = async () => {
    try {
      setIsLoading(true);
      const response = await enrollAssetToEnbalaService(assetName);
      if (response.status === 'success') {
        fetchAssetList();
        toggleModal();
      }
    } catch (error) {
      console.error('Error enrolling asset to enbala:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='flex flex-col items-center gap-6'>
      <h1 className='text-3xl font-bold text-center py-28'>
        {`Are you sure you want to enroll asset "${assetName}" to "Enbala"?`}
      </h1>
      <div className='flex flex-col justify-center gap-4 md:flex-row'>
        <button
          className='rounded-md py-2 text-sm flex justify-center'
          onClick={handleEnrollConfirm}
        >
          {isLoading ? 'Loading...' : 'Yes'}
        </button>
        <button
          className='rounded-md py-2 text-sm'
          onClick={toggleModal}
        >
          No
        </button>
      </div>
    </div>
  );
}
