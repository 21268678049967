const transformDateForBessVppInfoTables = (inputDate) => {
  let dateObject;

  // Check if inputDate is in "YYYY-MM-DD" format
  if (/^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
    const parts = inputDate.split('-');
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const day = parseInt(parts[2]);
    dateObject = new Date(year, month - 1, day);
  } else {
    // Try parsing the date in the alternate format
    dateObject = new Date(inputDate);
  }

  // Check if the dateObject is valid
  if (isNaN(dateObject.getTime())) {
    console.error('Invalid date:', inputDate);
    return null; // or handle the invalid date case as needed
  }

  // Define options for the output format
  const options = {
    weekday: 'short',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };

  // Use toLocaleDateString to format the date
  const formattedDate = dateObject.toLocaleDateString('en-US', options);

  return formattedDate;
}

export default transformDateForBessVppInfoTables;
