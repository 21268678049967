import React, { useState, useEffect } from 'react';

// Services
import { getProgramList as getProgramListService } from '../../services/programService';

// Access
import accessControl from '../../helpers/accessControl';

const AssetDrpTable = (
  {
    stateEH,
    setActiveProgram,
    setShowEnrollProgramModal,
    setShowUnenrollProgramModal,
  }
) => {
  const [programs, setPrograms] = useState([]);

  const getProgramList = async () => {
    try {
      const programList = await getProgramListService();
      setPrograms(programList);
    } catch (error) {
      console.error('Error fetching program list:', error);
    }
  };

  useEffect(() => {
    getProgramList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredPrograms = programs.filter(program => program.state === stateEH);

  const enrollProgram = (key) => {
    const program = filteredPrograms.find(p => p.programKey === key);
    setActiveProgram(program);
    setShowEnrollProgramModal(true);
  };

  const unenrollProgram = (key) => {
    const program = filteredPrograms.find(p => p.programKey === key);
    setActiveProgram(program);
    setShowUnenrollProgramModal(true);
  };

  return (
    <div
      className='table-container'
      style={filteredPrograms.length > 4 ? {
        maxHeight: 'calc(5 * 35px)',
        overflowY: 'auto',
      } : {}}
    >
      <table
        className='min-w-full bg-white text-center border border-black'>
        <thead>
          <tr>
            <th className='py-2'>ID</th>
            <th className='py-2'>Program Name</th>
            <th className='py-2'>Utility Name</th>
            <th className='py-2'>State</th>
            <th className='py-2'>Program Type</th>
            <th className='py-2'>Enrollment Status</th>
            {accessControl.isPlatformAdministrator() &&
              <th className='py-2'></th>
            }
          </tr>
        </thead>
        <tbody>
          {filteredPrograms.map((program) => (
            <tr key={program.programKey}>
              <td>{program.programKey}</td>
              <td>{program.programName}</td>
              <td>{program.utilityName}</td>
              <td>{program.state}</td>
              <td>{program.programType}</td>
              <td>{program.status}</td>
              {accessControl.isPlatformAdministrator() &&
                <td>
                  <button
                    style={{ minWidth: '6vw', minHeight: '2rem' }}
                    className='rounded-md px-2 py-1 text-xs mr-10'
                    onClick={() => enrollProgram(program.programKey)}
                  >
                    Enroll
                  </button>
                  <button
                    style={{ minWidth: '6vw', minHeight: '2rem' }}
                    className='rounded-md px-2 py-1 text-xs mr-0'
                    onClick={() => unenrollProgram(program.programKey)}
                  >
                    Unenroll
                  </button>
                </td>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AssetDrpTable;
