import React, { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

// Widget Components
import Widget  from '../widgets/Widget';
import AssetList from '../widgets/AssetList';
import Power from '../widgets/Power';
import FleetStatus from '../widgets/FleetStatus';
import Energy from '../widgets/Energy';
import Trees from '../widgets/TreesSaved';
import ScheduledEvents from '../widgets/ScheduledEvents';
import AssetLocations from '../widgets/AssetLocations';
import AssetListFaults from '../widgets/AssetListFaults';
import AssetListUnassigned from '../widgets/AssetListUnassigned';
import VppList from '../widgets/VppList';

// Helpers
import { idToText } from '../../helpers/widget/idToText';

const DashboardSelect = ({
  layout,
  events,
  assets,
  vpps,
  energy,
  power,
  treesCount,
  toggleHideWidget,
}) => {
  const ResponsiveGridLayout = WidthProvider(Responsive);

  const [hasHidden, setHasHidden] = useState(true);

  useEffect(() => {
    setHasHidden(layout.some(item => item.hidden === true));
  }, [layout]);

  if (hasHidden) {
    return (
      <div className="flex flex-col items-center my-6 max-h-full">
        <ResponsiveGridLayout
          style={{ minWidth: '100%', maxHeight: '100%' }}
          className="layout2 max-h-full"
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          measureBeforeMount={true}
          rowHeight={30}
          margin={[15, 15]}
          useCSSTransforms={false}
        >
          {layout?.map(element => {
            if (!!element.hidden) {
              // Make a human readable title
              const title = idToText(element.i);

              // Inject child compenents dynamically
              let componentSwitch;

              switch (element.i) {
                case 'assetList':
                  componentSwitch = <AssetList />;
                  break;
                case 'accountPower':
                  componentSwitch = <Power power={power} />;
                  break;
                case 'assetFaults':
                  componentSwitch = <AssetListFaults />;
                  break;
                case 'fleetStatus':
                  componentSwitch = <FleetStatus assets={assets} />;
                  break;
                case 'accountEnergy':
                  componentSwitch = <Energy energy={energy} />;
                  break;
                case 'treesSaved':
                  componentSwitch = <Trees treesCount={treesCount} />;
                  break;
                case 'scheduledDREvents':
                  componentSwitch = <ScheduledEvents events={events} />;
                  break;
                case 'assetLocations':
                  componentSwitch = <AssetLocations assets={assets}/>;
                  break;
                case 'AssetListUnassigned':
                  componentSwitch = <AssetListUnassigned />;
                  break;
                case 'VPPList':
                  componentSwitch = <VppList />;
                  break;
                default:
                  componentSwitch = null;
                  break;
              }

              return (
                <div
                  key={element.i}
                  className="container-border grid-component__container overflow-hidden"
                >
                  <Widget
                    title={title}
                    id={element.i}
                    addButton={true}
                    toggleHideWidget={toggleHideWidget}
                    child={componentSwitch}
                    assets={assets}
                    vpps={vpps}
                  />
                </div>
              );
            }

            return null;
          })}
        </ResponsiveGridLayout>
      </div>
    );
  }
  return <div>There are no hidden widgets.</div>;
};

export default DashboardSelect;
