const ScheduledEvents = ({filteredEvents = []}) => {
  return (
    <div className="border rounded-lg grow pb-2 px-2 mx-3 md:px-4 md:mx-8 overflow-auto text-sm md:text-base relative">
      <table className="table-auto w-full ">
        <thead className="table-head">
          <tr>
            <th className="table-head-item">Asset Name</th>
            <th className="table-head-item">Event Name</th>
            <th className="table-head-item">Event Status</th>
            <th className="table-head-item">Scheduled Start</th>
            <th className="table-head-item">Scheduled End</th>
            <th className="table-head-item">Event Author</th>
          </tr>
        </thead>
        <tbody>
          {filteredEvents?.map(item => {
            return (
              <tr key={item._id} className="border-b">
                <td className="pr-2 py-2 ">{item.assetName}</td>
                <td className="pr-2 py-2 ">{item._id}</td>
                <td className="pr-2 py-2 ">{item.state}</td>
                <td className="pr-2 py-2 ">{item.startTime}</td>
                <td className="pr-2 py-2 ">{item.endTime}</td>
                <td className="pr-2 py-2 ">{item.created_by}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduledEvents;
